import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../components/forms/Button";

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { message } = location.state || {
    message: "An unknown error occurred.",
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-style-bg">
      <ExclamationCircleIcon className="w-24 h-24 text-morange-400" />
      <h1 className="text-4xl font-inter font-bold text-morange-300 mt-6">
        Sorry! We've encountered an error.
      </h1>
      <div className="max-w-prose">
        <p className="text-base font-inter font-light text-slate-900 my-8">
          {message}
        </p>
      </div>
      <Button onClick={() => navigate("/")}>Go Back</Button>
    </div>
  );
};

export { ErrorPage };
