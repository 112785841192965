import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import { MainContent } from "./MainContent";
import { ContextBar } from "./ContextBar";
import { NavBar } from "./NavBar";

const ScrollableLayout = ({
  queryString = undefined,
  comparisonQueryString = undefined,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const comparisonPrefix = "comparison_";
  const queryParamsObj = {};
  const comparisonQueryParamsObj = {};

  queryParams.forEach((value, key) => {
    if (key.startsWith(comparisonPrefix)) {
      comparisonQueryParamsObj[key.replace(comparisonPrefix, "")] = value;
    } else {
      queryParamsObj[key] = value;
    }
  });

  if (!queryString) {
    queryString = new URLSearchParams(queryParamsObj).toString();
  }

  if (!comparisonQueryString) {
    if (Object.keys(comparisonQueryParamsObj).length === 0) {
      comparisonQueryString = undefined;
    } else {
      comparisonQueryString = new URLSearchParams(
        comparisonQueryParamsObj
      ).toString();
    }
  }

  return (
    <div className="bg-style-bg w-full h-full">
      {/* <NavBar /> */}
      <div className="container mx-auto">
        <div className="mx-auto flex w-full max-w-7xl items-start gap-x-8 px-4">
          <MainContent
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
          <ContextBar
            queryString={queryString}
            comparisonQueryString={comparisonQueryString}
          />
        </div>
      </div>
    </div>
  );
};

export default ScrollableLayout;
