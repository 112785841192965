import React, { useState, useCallback, useMemo, memo } from "react";

const ChartContainer = React.forwardRef(
  ({ children, onMouseEnter, onMouseLeave }, ref) => (
    <div
      ref={ref}
      className="relative"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
);

export { ChartContainer };
