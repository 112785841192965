import React, { useMemo } from "react";
import { AnimatedBar, AnimatedText } from "./Bars.js";
import { withParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { hatch } from "ldrs";
import { AxisLeft } from "@visx/axis";
import { Text } from "@visx/text";
import { formatLargeNumber } from "../../utility/numberFormats.js";

hatch.register();
const { colors } = require("../../utility/colors.js");

const BaseHorizontalBarChart = ({
  chartData = null,
  comparisonData,
  height = null,
  width = null,
  parentHeight = null,
  parentWidth = null,
  color = Object.values(colors.msky).slice(3),
  verticalMargin = 0,
  horizontalMargin = 120,
  labelAreaWidth = 60,
  barPadding = 0.3,
  axisTitle = null,
  loading = false,
  dataLabelFormatter = null,
  isPercentage = false,
  valueMin = null,
  valueMax = null,
}) => {
  height = height || parentHeight;
  width = width || parentWidth;

  if (!dataLabelFormatter) {
    if (isPercentage) {
      dataLabelFormatter = (d) => `${(d * 100).toFixed(0)}%`;
    } else {
      dataLabelFormatter = formatLargeNumber;
    }
  }

  // Set the margins:
  const xMax = width - horizontalMargin - labelAreaWidth;
  const yMax = height - verticalMargin;

  // Calculate the min/max across all chart and comparison (if exists) data
  if (comparisonData) {
    const comparisonDataValues = comparisonData.map((d) => d.value);
    const chartDataValues = chartData.map((d) => d.value);
    valueMin = valueMin || 0;
    valueMax =
      valueMax || Math.max(...comparisonDataValues, ...chartDataValues);
  } else {
    valueMin = valueMin || 0;
    valueMax = valueMax || Math.max(...chartData.map((d) => d.value));
  }

  // Create the x and y scales:
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [valueMin, valueMax],
      }),
    [xMax, chartData, comparisonData]
  );

  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        domain: chartData.map((d) => d.label),
        padding: barPadding,
      }),
    [yMax, chartData, comparisonData]
  );

  const barHeight =
    comparisonData.length > 0 ? yScale.bandwidth() / 2 : yScale.bandwidth();

  return width < 10 || height < 10 ? null : (
    <>
      {loading && (
        <div className="relative">
          <div
            className={`w-full h-[${height}px] flex items-center justify-center absolute`}
          >
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      )}
      <svg width={width} height={height} className="">
        <Group
          top={verticalMargin / 2}
          left={horizontalMargin / 2 + labelAreaWidth}
        >
          {chartData.map((d, i) => {
            const barWidth = xScale(d.value);
            const barX = 0;
            const barY = yScale(d.label);
            return (
              <React.Fragment key={`bar-${d.label}`}>
                <AnimatedBar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={colors.chartColors["primary"]}
                  direction="right"
                />
                <AnimatedText
                  x={barX + barWidth + 10}
                  y={barY + barHeight / 2}
                  text={dataLabelFormatter(Math.abs(d.value))}
                  textAnchor="start"
                  fill={colors.mcharcoal["700"]}
                  width={horizontalMargin / 2}
                />
              </React.Fragment>
            );
          })}
          {comparisonData &&
            chartData.map((d, i) => {
              const comparisonDatum = comparisonData.find(
                (cd) => cd.label === d.label
              );
              if (comparisonDatum) {
                const barWidth = xScale(comparisonDatum.value);
                const barX = 0;
                const barY = yScale(comparisonDatum.label) + barHeight;
                return (
                  <React.Fragment key={`bar-${comparisonDatum.label}`}>
                    <AnimatedBar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={colors.chartColors["secondary"]}
                      direction="right"
                    />
                    <AnimatedText
                      x={barX + barWidth + 10}
                      y={barY + barHeight / 2}
                      text={dataLabelFormatter(Math.abs(comparisonDatum.value))}
                      textAnchor="start"
                      fill={colors.mcharcoal["700"]}
                      width={horizontalMargin / 2}
                    >
                      {dataLabelFormatter(Math.abs(comparisonDatum.value))}
                    </AnimatedText>
                  </React.Fragment>
                );
              }
            })}
        </Group>
        <AxisLeft
          top={verticalMargin / 2}
          left={horizontalMargin / 2 + labelAreaWidth}
          scale={yScale}
          stroke={colors.mcharcoal["700"]}
          strokeWidth={2}
          tickComponent={(props) => {
            const { x, y, formattedValue, opacity } = props;
            return (
              <Text
                x={x - 5}
                y={y}
                height={yScale.bandwidth()}
                width={horizontalMargin / 2 + labelAreaWidth * 0.7}
                fill={colors.mcharcoal["700"]}
                fontSize={14}
                textAnchor="end"
                verticalAnchor="middle"
                className="text-sm"
                opacity={opacity}
              >
                {formattedValue}
              </Text>
            );
          }}
        />
        {axisTitle && (
          <AnimatedText
            x={horizontalMargin / 2 + labelAreaWidth + xMax / 2}
            y={height - 5}
            fill={colors.mcharcoal["700"]}
            fontSize={14}
            textAnchor="middle"
            verticalAnchor="end"
            text={axisTitle}
          />
        )}
      </svg>
    </>
  );
};

const HorizontalBarChart = withParentSize(BaseHorizontalBarChart);

export { HorizontalBarChart };
