function Subtitle({ children, className }) {
  return (
    <div
      className={`text-base font-inter font-light tracking-tighter text-slate-600 pb-2 ${className}`}
    >
      {children}
    </div>
  );
}

export { Subtitle };
