// Adds layers to the Mapbox map instance to create a choropleth map
import { formatTitle } from "../formatText";
import { formatLargeNumber } from "../numberFormats";

function defaultGradient(min, max) {
  const colors = ["#E07A5F", "#EAB69F", "#CCD4C5", "#85A9A6", "#15616D"];

  const steps = colors.length;
  return colors.map((color, i) => [
    min + (max - min) * (i / (steps - 1)),
    color,
  ]);
}

const generateColorScale = (features, metric) => {
  // Get all valid values for this metric
  const values = features
    .map((f) => f.properties[metric])
    .filter((v) => v != null);

  if (values.length === 0) return [];

  const min = Math.min(...values);
  const max = Math.max(...values);

  // Add helper function to detect if data is heavily skewed
  const isHighlySkewed = (values) => {
    const sortedValues = [...values].sort((a, b) => a - b);
    const median = sortedValues[Math.floor(sortedValues.length * 0.5)];
    const max = sortedValues[sortedValues.length - 1];

    // Ratio between max and median
    // Should be ~2 if it's a linear scale
    const maxMedianRatio = max / median;

    return maxMedianRatio > 10;
  };

  // Special cases for specific metrics
  if (metric === "average_partisan_score") {
    return [
      [0, "#780000"], // Strong Republican
      [25, "#FF6767"], // Lean Republican
      [50, "#fdf0d5"], // Neutral
      [75, "#669bbc"], // Lean Democrat
      [100, "#003049"], // Strong Democrat
    ];
  }

  // For percentage metrics
  else if (values.every((v) => v <= 1)) {
    return defaultGradient(0, 1);
  }

  // For score metrics
  else if (values.every((v) => v <= 100)) {
    return defaultGradient(0, 100);
  }

  const colors = defaultGradient(min, max);

  // For numeric metrics, check if highly skewed
  const steps = colors.length;
  if (isHighlySkewed(values)) {
    const logMin = Math.log(min || 1); // Avoid log(0)
    const logMax = Math.log(max);
    const logStep = (logMax - logMin) / (steps - 1);

    return Array.from({ length: steps }, (_, i) => {
      const logValue = logMin + logStep * i;
      const value = Math.exp(logValue);
      return [value, colors[i][1]];
    });
  }

  return colors;
};

export const addMapLayer = (map, geojson, metric) => {
  const colorScale = generateColorScale(geojson.features, metric);

  // If no valid color scale could be generated, use a default
  if (colorScale.length === 0) {
    console.warn(`No valid data range for metric: ${metric}`);
    return;
  }

  // Add a layer with polygons colored based on the 'value' property
  map.addLayer({
    id: "polygons-fill",
    type: "fill",
    source: "polygons",
    paint: {
      "fill-color": [
        "case",
        ["!=", ["get", metric], null],
        ["interpolate", ["linear"], ["get", metric], ...colorScale.flat()],
        "rgba(0, 0, 0, 0.0)", // Transparent if no value
      ],
      "fill-opacity": ["case", ["!=", ["get", metric], null], 0.7, 0],
    },
  });

  // Add outline layer
  map.addLayer({
    id: "polygons-outline",
    type: "line",
    source: "polygons",
    layout: {},
    paint: {
      "line-color": "#808080",
      "line-width": 0.5,
    },
  });

  // Add legend
  addLegend(map, colorScale, metric);
};

const addLegend = (map, colorScale, metric) => {
  // Remove existing legend if it exists
  const existingLegend = document.getElementById("map-legend");
  if (existingLegend) {
    existingLegend.remove();
  }

  const legend = document.createElement("div");
  legend.id = "map-legend";
  legend.className = "absolute bottom-8 right-8 bg-white p-4 rounded shadow-lg";

  const title = document.createElement("h4");
  title.className = "text-sm font-semibold mb-2";
  title.textContent = formatTitle(metric);
  legend.appendChild(title);

  colorScale.forEach(([value, color]) => {
    const item = document.createElement("div");
    item.className = "flex items-center gap-2 text-xs";

    const colorBox = document.createElement("div");
    colorBox.className = "w-4 h-4";
    colorBox.style.backgroundColor = color;

    const label = document.createElement("span");
    label.textContent =
      Math.max(...colorScale.map(([value]) => value)) <= 1
        ? `${Math.round(value * 100)}%`
        : formatLargeNumber(value);

    item.appendChild(colorBox);
    item.appendChild(label);
    legend.appendChild(item);
  });

  map.getContainer().appendChild(legend);
};
