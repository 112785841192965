import React, { useState, useCallback, useRef } from "react";
import BarChart from "../charts/BarChart";
import { Card } from "../layouts/Card";
import { ControlButtons } from "../charts/ControlButtons";
import { ChartContainer } from "../charts/ChartContainer";
import { downloadChartAsPng } from "../../utility/downloadChart";
import useFetch from "../../hooks/fetchData";

const BarChartCard = ({
  title,
  subtitle,
  footnote = null,
  endpoint,
  comparisonEndpoint,
  defaultToPercentage = false,
  colspan = 1,
  width = null,
  height = 300,
  outline = false,
  className = "",
  chartProps = {},
  id = null,
  children = null,
}) => {
  const { data, loading, error, toggleDataFormat, isPercentage } = useFetch(
    endpoint,
    defaultToPercentage
  );
  const {
    data: comparisonData = null,
    loading: comparisonLoading = false,
    error: comparisonError = null,
    toggleDataFormat: comparisonToggleDataFormat = null,
    isPercentage: comparisonIsPercentage = null,
  } = useFetch(comparisonEndpoint, defaultToPercentage);

  const [showControls, setShowControls] = useState(false);
  const chartRef = useRef(null);

  const handleMouseEnter = useCallback(() => setShowControls(true), []);
  const handleMouseLeave = useCallback(() => setShowControls(false), []);

  const handleDownload = useCallback(() => {
    downloadChartAsPng(chartRef, `${title}-bar-chart`);
  }, [title]);

  const isLoading = comparisonLoading ? loading && comparisonLoading : loading;
  const hasError = error || comparisonError;
  const combinedIsPercentage = isPercentage || comparisonIsPercentage;

  const combinedToggleDataFormat = useCallback(() => {
    toggleDataFormat();
    if (comparisonToggleDataFormat) {
      comparisonToggleDataFormat();
    }
  }, [toggleDataFormat, comparisonToggleDataFormat]);

  return (
    <Card
      title={title}
      subtitle={subtitle}
      footnote={footnote}
      colspan={colspan}
      outline={outline}
      className={className}
      id={id}
    >
      {children}
      <ChartContainer
        ref={chartRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data={data}
        isPercentage={combinedIsPercentage}
        toggleDataFormat={combinedToggleDataFormat}
      >
        {showControls && (
          <ControlButtons
            data={data}
            isPercentage={combinedIsPercentage}
            toggleDataFormat={combinedToggleDataFormat}
            downloadAsPng={handleDownload}
          />
        )}
        <div className="flex self-center w-full">
          <BarChart
            loading={isLoading}
            error={hasError}
            chartData={data}
            comparisonData={comparisonData}
            isPercentage={combinedIsPercentage}
            width={width}
            height={height}
            {...chartProps}
          />
        </div>
      </ChartContainer>
    </Card>
  );
};

export default BarChartCard;
