import { useState, useEffect, useCallback } from "react";
import apiClient from "../utility/axios";

// Convert to just general purpose backend data fetching hook
const useBackendData = (apiUrl, defaultToPercentage = false) => {
  const [rawData, setRawData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPercentage, setIsPercentage] = useState(defaultToPercentage);

  useEffect(() => {
    const fetchData = async () => {
      if (apiUrl) {
        try {
          setLoading(true);
          setRawData([]);
          setDisplayData([]);
          const response = await apiClient.get(`/api/${apiUrl}`);
          let result;
          if (typeof response.data === "string") {
            result = JSON.parse(response.data);
          } else {
            result = response.data;
          }
          setRawData(result);

          if (isPercentage) {
            setDisplayData(
              result.map((item) => ({
                ...item,
                // If total is not 0, calculate the percentage otherwise set to 0
                value: item.total !== 0 ? item.value / item.total : 0,
              }))
            );
          } else {
            setDisplayData(result);
          }
          setLoading(false);
        } catch (error) {
          console.error("There was an error fetching the data!", error);
          setError(error);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [apiUrl]);

  const toggleDataFormat = useCallback(() => {
    setIsPercentage((prev) => !prev);
    if (!isPercentage) {
      setDisplayData(
        rawData.map((item) => ({
          ...item,
          // If total is not 0, calculate the percentage otherwise set to 0
          value: item.total !== 0 ? item.value / item.total : 0,
        }))
      );
    } else {
      // Revert to original counts
      setDisplayData(rawData);
    }
  }, [rawData, isPercentage]);

  return { data: displayData, loading, error, toggleDataFormat, isPercentage };
};

const createUseBackendData = (apiUrl, defaultToPercentage = false) => {
  return () => useBackendData(apiUrl, defaultToPercentage);
};

const useRandomData = (length = 5) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      try {
        const randomData = Array.from({ length: length }, () => ({
          x: Math.random().toString(36).substring(7),
          y: Math.floor(Math.random() * 1000000),
        }));
        setData(randomData);
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [length]);

  return { data, loading, error };
};

const createRandomData = (length) => {
  return () => useRandomData(length);
};

export {
  createUseBackendData,
  useBackendData,
  useRandomData,
  createRandomData,
};
