const oktaConfig = {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_BASE_URL,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: false,
    responseType: ['token', 'id_token'],
  };
  
  export default oktaConfig;
  