import { createSlice } from "@reduxjs/toolkit";
import {
  scores,
  demographicCategories,
  turnoutScores,
} from "../pages/district/config";

const initialState = {
  scores: {
    selected: "partisan",
  },
  turnoutScores: {
    score: "presidential_general_turnout",
    demographic: "ethnicity",
  },
  demographicFilters: {
    age: [],
    ethnicity: [],
    education: [],
    gender: [],
  },
  contextBar: {
    visibleSection: null,
  },
  selectedTab: "Demographics",
  districtInfo: {
    name: null,
    registeredVoters: null,
  },
  comparisonInfo: {
    name: null,
    registeredVoters: null,
  },
  earlyVoteComparison: "No Comparison",
  earlyVoteVoteType: "total",
};

const districtPageSlice = createSlice({
  name: "districtPage",
  initialState,
  reducers: {
    toggleSelectedScore: (state, action) => {
      const { score } = action.payload;
      if (scores.includes(score)) {
        state.scores.selected = score;
      }
    },
    toggleTurnoutScore: (state, action) => {
      const { score } = action.payload;
      if (turnoutScores.includes(score)) {
        state.turnoutScores.score = score;
      }
    },
    toggleTurnoutDemographic: (state, action) => {
      const { demographic } = action.payload;
      if (
        demographicCategories.includes(demographic) &&
        demographic !== "education" &&
        demographic !== "income"
      ) {
        state.turnoutScores.demographic = demographic;
      }
    },
    toggleDemographicFilter: (state, action) => {
      const { category, value } = action.payload;
      if (state.demographicFilters[category].includes(value)) {
        state.demographicFilters[category] = state.demographicFilters[
          category
        ].filter((item) => item !== value);
      } else {
        state.demographicFilters[category].push(value);
      }
    },
    setContextBarVisibleSection: (state, action) => {
      state.contextBar.visibleSection = action.payload;
    },
    setSelectedTab: (state, action) => {
      // Added reducer for selected tab
      console.log(state, action);
      state.selectedTab = action.payload;
    },
    setDistrictInfo: (state, action) => {
      state.districtInfo = action.payload;
    },
    setComparisonInfo: (state, action) => {
      state.comparisonInfo = action.payload;
    },
    setEarlyVoteComparison: (state, action) => {
      state.earlyVoteComparison = action.payload;
    },
    setEarlyVoteVoteType: (state, action) => {
      state.earlyVoteVoteType = action.payload;
    },
  },
});

export const {
  toggleSelectedScore,
  toggleTurnoutScore,
  toggleTurnoutDemographic,
  toggleDemographicFilter,
  setContextBarVisibleSection,
  setSelectedTab,
  setDistrictInfo,
  setComparisonInfo,
  setEarlyVoteComparison,
  setEarlyVoteVoteType,
} = districtPageSlice.actions;

export default districtPageSlice.reducer;
