import React, { useEffect, useState, useCallback } from "react";
import apiClient from "../../utility/axios";
import { quantum } from "ldrs";
import { Footnote } from "./Footnote";

quantum.register();

const cache = new Map();

/**
 * GeographyDescription Component
 *
 * This component fetches and displays a description for a given geographic query.
 * It uses caching to improve performance for repeated queries.
 *
 * @component
 * @param {Object} props
 * @param {string} props.geoQuery - The geographic query string used to fetch the description
 *
 * @returns {JSX.Element} A div containing the fetched description or a loading indicator
 */
function GeographyDescription({ geoQuery }) {
  const [data, setData] = useState({
    geoid_name: "",
    description: "",
    isLoading: true,
  });

  /**
   * Fetches data for the given geographic query
   * Uses cached data if available, otherwise makes an API call
   */
  const fetchData = useCallback(async () => {
    if (geoQuery !== null) {
      if (cache.has(geoQuery)) {
        setData({ ...cache.get(geoQuery), isLoading: false });
        return;
      }

      try {
        const response = await apiClient.get(`/api/openai?${geoQuery}`);
        const newData = {
          geoid_name: response.data.geoid_name,
          description: response.data.description,
        };
        cache.set(geoQuery, newData);
        setData({ ...newData, isLoading: false });
      } catch (error) {
        console.error("Error fetching geoid data:", error);
        setData({
          geoid_name: "Error",
          description: "Failed to fetch data. Please try again.",
          isLoading: false,
        });
      }
    }
  }, [geoQuery]);

  // Trigger data fetching when the component mounts or geoQuery changes
  useEffect(() => {
    setData((prevData) => ({ ...prevData, isLoading: true }));
    fetchData();
  }, [fetchData]);

  if (data.isLoading) {
    return (
      <div className="text-center">
        <l-quantum size="45" speed="1.75" color="black"></l-quantum>
      </div>
    );
  }

  return (
    <div>
      {data.description}
      <Footnote>Source: OpenAI</Footnote>
    </div>
  );
}

export default GeographyDescription;
