import {
  Card,
  BarChartCard,
  StatBoxesCard,
} from "../../components/layouts/Cards";
import { createUseBackendData } from "../../hooks/data";
import { useSelector, useDispatch } from "react-redux";
import { ReusableCombobox } from "../../components/forms/ReusableCombobox";
import {
  toggleSelectedScore,
  toggleTurnoutDemographic,
} from "../../store/districtPage";
import { demographicCategories } from "./config";

const capitalizeFormat = (string) => {
  return `${string
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")}`;
};

const MainScoreSection = ({ queryString, comparisonQueryString }) => {
  const dispatch = useDispatch();
  const score = useSelector((state) => state.districtPage.scores.selected);
  const demographic = useSelector(
    (state) => state.districtPage.turnoutScores.demographic
  );

  const handleDemographicSelect = (demographic) => {
    dispatch(toggleTurnoutDemographic({ demographic }));
  };

  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Scores</p>}
        id="scores"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />{" "}
          </>
        }
        className="pt-4"
      />
      <BarChartCard
        key="score-distribution"
        id="score-distribution"
        title={`${score} Score Distribution`
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        subtitle="Count of voters by score bucket"
        endpoint={`voterfile/score/buckets?${queryString}&score=${score}`}
        comparisonEndpoint={
          comparisonQueryString !== undefined
            ? `voterfile/score/buckets?${comparisonQueryString}&score=${score}`
            : undefined
        }
        chartProps={{
          xPadding: 0.0,
          valueFontSize: comparisonQueryString !== undefined ? 11 : 14,
        }}
        colspan={12}
      />
      <BarChartCard
        key="score-demographics"
        id="score-demographics"
        title={`${score} Score`
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        subtitle={`Average score broken out by ${capitalizeFormat(demographic)}`}
        endpoint={`voterfile/score/avg?${queryString}&score=${score}&demographic_category=${demographic}`}
        comparisonEndpoint={
          comparisonQueryString !== undefined
            ? `voterfile/score/avg?${comparisonQueryString}&score=${score}&demographic_category=${demographic}`
            : undefined
        }
        colspan={12}
        chartProps={{
          valueMax: 100,
        }}
        children={
          <div className="absolute top-0 right-0 p-4">
            <ReusableCombobox
              items={demographicCategories}
              selected={demographic}
              onSelect={handleDemographicSelect}
              displayValue={capitalizeFormat}
              formatOption={capitalizeFormat}
            />
          </div>
        }
      />
    </>
  );
};

export { MainScoreSection };
