import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDispatch, useSelector } from "react-redux";
import { MetricsTab } from "./MetricsTab";
import { FiltersTab } from "./FiltersTab";
import { PlacesTab } from "./PlacesTab";
import { tabColors } from "../../pages/search/config";
import { Badge } from "../text/Badge";
import { Button } from "../forms/Button";

const DataCatalogModal = ({
  isOpen,
  onClose,
  onApplySelection,
  firstActiveTab = "metrics",
}) => {
  const dispatch = useDispatch();
  const { parameters } = useSelector((state) => state.search);
  const [activeTab, setActiveTab] = useState(firstActiveTab);
  const [tabColor, setTabColor] = useState(tabColors[firstActiveTab]);
  const tabRefs = useRef([]);
  const tabs = ["metrics", "filters", "places"];
  const [localParameters, setLocalParameters] = useState({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current && parameters) {
      const filteredParameters = Object.keys(parameters)
        .filter((key) => tabs.includes(key) || key === "geographies")
        .reduce((obj, key) => {
          obj[key] = parameters[key];
          return obj;
        }, {});
      setLocalParameters(filteredParameters);
      isFirstRender.current = false;
    }
  }, [parameters]);

  const [underlineProps, setUnderline] = useSpring(() => ({
    left: 0,
    width: 0,
  }));

  useEffect(() => {
    setTabColor(tabColors[activeTab]);

    const activeTabIndex = tabs.indexOf(activeTab);
    if (activeTabIndex !== -1 && tabRefs.current[activeTabIndex]) {
      const tabElement = tabRefs.current[activeTabIndex];
      setUnderline({
        left: tabElement.offsetLeft,
        width: tabElement.offsetWidth,
      });
    }
  }, [activeTab]);

  const handleItemSelect = (item, type) => {
    if (type === "places") {
      setLocalParameters((prev) => ({
        ...prev,
        [type]: [...(prev[type] || []), item.geography_pair],
        geographies: [
          ...(prev.geographies || []),
          {
            name: item.name,
            geo_query: item.geo_query,
            geography_level: item.geography_level,
          },
        ],
        search: "advanced",
      }));
    } else {
      setLocalParameters((prev) => ({
        ...prev,
        [type]: [...(prev[type] || []), item],
      }));
    }
  };

  const handleItemRemove = (item, type) => {
    if (type === "places") {
      setLocalParameters((prev) => {
        const placeIndex = prev[type].indexOf(item);
        return {
          ...prev,
          [type]: prev[type].filter((p) => p !== item),
          geographies: prev.geographies
            ? prev.geographies.filter((_, index) => index !== placeIndex)
            : prev.geographies,
        };
      });
    } else {
      setLocalParameters((prev) => ({
        ...prev,
        [type]: prev[type].filter((p) => p !== item),
      }));
    }
  };

  const handleApplySelection = () => {
    // Check if localParameters is empty
    if (Object.keys(localParameters).length === 0) {
      return;
    }

    // Create a new object with all original parameters
    const updatedParameters = { ...parameters };

    // Update the parameters with localParameters values
    Object.keys(localParameters).forEach((key) => {
      updatedParameters[key] = localParameters[key];
    });

    // Convert 'metrics' and 'filters' values to lowercase and replace spaces with underscores
    if (updatedParameters.metrics) {
      updatedParameters.metrics = updatedParameters.metrics.map((item) =>
        item.toLowerCase().replace(/\s/g, "_")
      );
    }
    if (updatedParameters.filters) {
      updatedParameters.filters = updatedParameters.filters.map((item) =>
        item.toLowerCase().replace(/\s/g, "_")
      );
    }

    console.log("UPDATED PARAMETERS", updatedParameters);
    onApplySelection(updatedParameters);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? "flex" : "hidden"} items-start justify-center bg-black bg-opacity-50`}
      style={{ paddingTop: "10vh" }}
    >
      <div className="bg-style-bg rounded-lg shadow-lg max-w-4xl w-full max-h-[85vh] p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">Data Catalog</h2>
          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="border-b border-gray-200 mb-6 relative">
          <nav className="flex space-x-4">
            {tabs.map((tab, index) => (
              <button
                key={tab}
                ref={(el) => (tabRefs.current[index] = el)}
                className={`py-2 px-4 ${activeTab === tab ? `text-${tabColor} border-b-2 border-${tabColor}` : `text-gray-600 hover:text-${tabColors[tab]}`} font-medium`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
          <animated.div
            className={`absolute bottom-0 h-0.5 bg-${tabColor}`}
            style={{ left: underlineProps.left, width: underlineProps.width }}
          />
        </div>

        <div className="py-2 relative h-auto">
          {activeTab === "metrics" && (
            <MetricsTab
              activeTab={activeTab}
              onMetricSelect={(item) => handleItemSelect(item, "metrics")}
            />
          )}
          {activeTab === "filters" && (
            <FiltersTab
              activeTab={activeTab}
              onFilterSelect={(item) => handleItemSelect(item, "filters")}
            />
          )}
          {activeTab === "places" && (
            <PlacesTab onSearch={(item) => handleItemSelect(item, "places")} />
          )}
        </div>

        <div className="mt-4 border-t border-gray-300 p-4">
          <p className="text-center mb-2">You're searching for:</p>
          {Object.keys(localParameters).map(
            (type) =>
              localParameters[type] &&
              localParameters[type].length > 0 &&
              type !== "geographies" &&
              type !== "search" && (
                <div
                  key={type}
                  className="mb-2 mt-4 flex flex-wrap items-center"
                >
                  <div className="mr-2">
                    {type.charAt(0).toUpperCase() + type.slice(1)}:
                  </div>
                  {Array.isArray(localParameters[type]) &&
                    localParameters[type].map((item) => (
                      <Badge
                        key={item}
                        className={
                          tabColors[type]
                            ? `!text-${tabColors[type]} !ring-${tabColors[type]} mx-1 my-1 flex items-center`
                            : "flex items-center"
                        }
                        onClick={() =>
                          item !== "registered_voters" &&
                          handleItemRemove(item, type)
                        }
                      >
                        {type === "places"
                          ? `${item}`
                          : item
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                        {item !== "registered_voters" && (
                          <span className="ml-2 text-black cursor-pointer">
                            x
                          </span>
                        )}
                      </Badge>
                    ))}
                </div>
              )
          )}
        </div>

        <div className="flex justify-end mt-4">
          <Button
            className="font-medium text-slate-700 bg-morange-100 hover:bg-morange-200 hover:ring-2 hover:ring-morange-200 outline outline-2 outline-morange-200 !mt-0 !mb-0"
            onClick={handleApplySelection}
          >
            Apply Selection
          </Button>
        </div>
      </div>
    </div>
  );
};

export { DataCatalogModal };
