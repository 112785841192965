function Footnote({ children, className }) {
  return (
    <div
      className={`text-sm font-inter font-light tracking-tighter text-slate-500 pt-1 ${className}`}
    >
      {children}
    </div>
  );
}

export { Footnote };
