import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const logout = async () => {
      // Sign out the user from Okta
      await oktaAuth.signOut();
    };

    logout();
  }, [oktaAuth]);

  return <div>Logging out...</div>;
};

export default Logout;
