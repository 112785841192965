// Fetch boundary data from the FastAPI backend
import apiClient from "../axios";

// Create a cache Map outside the function to persist between calls in the client's memory
const cache = new Map();
// Optional: Set cache expiry time (in milliseconds), e.g., 5 minutes
const CACHE_EXPIRY = 5 * 60 * 1000;

async function fetchMapData(url, geoQueries) {
  // Create a cache key from the URL and queries
  const cacheKey = JSON.stringify({ url, geoQueries });

  // Check if we have a valid cached response
  const cachedData = cache.get(cacheKey);
  if (cachedData) {
    const { data, timestamp } = cachedData;
    if (Date.now() - timestamp < CACHE_EXPIRY) {
      return data;
    }
    // Remove expired cache entry
    cache.delete(cacheKey);
  }

  try {
    const response = await apiClient.post(url, {
      geo_queries: geoQueries,
    });

    const data =
      typeof response.data === "string"
        ? JSON.parse(response.data)
        : response.data;

    // Store in cache with timestamp
    cache.set(cacheKey, {
      data,
      timestamp: Date.now(),
    });

    return data;
  } catch (error) {
    console.error("There was an error fetching the data!", error);
    throw error;
  }
}

export default fetchMapData;
