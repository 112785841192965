import React, { useMemo, useState, useCallback } from "react";
import { formatLargeNumber } from "../../utility/numberFormats";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Button } from "../forms/Button";

const guessColumnTypes = (data) => {
  if (!data || data.length === 0) return {};

  const columnTypes = {};
  const headers = Object.keys(data[0]);

  headers.forEach((header) => {
    const values = data.map((row) => row[header]).filter((val) => val != null);
    if (values.length === 0) {
      columnTypes[header] = "text";
    } else if (values.every((val) => typeof val === "number")) {
      columnTypes[header] = values.every((val) => val >= 0 && val <= 1)
        ? "percentage"
        : "numeric";
    } else {
      columnTypes[header] = "text";
    }
  });
  return columnTypes;
};

const Table = ({ data, columnConfig = [], showExportButton = false }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const columns = useMemo(() => {
    const guessedTypes = guessColumnTypes(data);
    const allKeys = Object.keys(data[0] || {});
    const configuredKeys = new Set(columnConfig.map((col) => col.key));

    const missingColumns = allKeys
      .filter((key) => !configuredKeys.has(key))
      .map((key) => ({ key, type: guessedTypes[key] }));

    return [...columnConfig, ...missingColumns];
  }, [data, columnConfig]);

  const visibleColumns = useMemo(() => {
    const hrefSourceColumns = new Set(
      columns.filter((col) => col.type === "link").map((col) => col.hrefSource)
    );
    return columns.filter((col) => !hrefSourceColumns.has(col.key));
  }, [columns]);

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "ascending" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const columnMaxValues = useMemo(() => {
    const maxValues = {};
    if (sortedData && sortedData.length > 0) {
      visibleColumns.forEach((column) => {
        if (column.type === "numeric") {
          const maxValue = Math.max(
            ...sortedData.map((row) => row[column.key] || 0)
          );
          maxValues[column.key] = maxValue <= 100 ? 100 : maxValue;
        } else if (column.type === "percentage") {
          maxValues[column.key] = 1;
        }
      });
    }
    return maxValues;
  }, [sortedData, visibleColumns]);

  const convertToCSV = useCallback(
    (data) => {
      const headers = visibleColumns.map((col) => col.key);
      const csvRows = [
        headers.join(","),
        ...data.map((row) =>
          headers
            .map((header) => {
              const escaped = `${row[header]}`.replace(/"/g, '\\"');
              return `"${escaped}"`;
            })
            .join(",")
        ),
      ];
      return csvRows.join("\n");
    },
    [visibleColumns]
  );

  const exportToCSV = useCallback(() => {
    const csv = convertToCSV(sortedData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  }, [convertToCSV, sortedData]);

  const getColumnClass = useCallback((column) => {
    const type = column.type || "text";
    return type === "numeric" || type === "percentage"
      ? "text-right max-w-[5rem]"
      : "";
  }, []);

  const formatCellContent = useCallback((value, column) => {
    const type = column.type || "text";
    if (type === "numeric" && typeof value === "number") {
      return formatLargeNumber(value);
    } else if (type === "percentage" && typeof value === "number") {
      return `${(value * 100).toFixed(0)}%`;
    }
    return value;
  }, []);
  const renderCell = useCallback(
    (row, column) => {
      const value = row[column.key];
      const formattedValue = formatCellContent(value, column);

      if (column.type === "link") {
        let href = row[column.hrefSource];
        if (column.hrefModifier && typeof column.hrefModifier === "function") {
          href = column.hrefModifier(href, row);
        }
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-style-secondary hover:underline flex items-center h-full"
          >
            {formattedValue}
          </a>
        );
      }
      if (
        (column.type === "numeric" || column.type === "percentage") &&
        typeof value === "number"
      ) {
        const maxValue = columnMaxValues[column.key];
        const percentage = (value / maxValue) * 100;
        return (
          <div className="flex items-center justify-end h-full font-inter font-medium text-sm text-mcharcoal-900">
            <span className="w-1/2 text-right mr-3">{formattedValue}</span>
            <div className="w-1/2 h-3 bg-gray-200">
              <div
                className="h-full bg-style-secondary"
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          </div>
        );
      }

      return <div className="flex items-center h-full">{formattedValue}</div>;
    },
    [columnMaxValues, formatCellContent]
  );

  const requestSort = useCallback((key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  }, []);

  if (!data || data.length === 0) {
    return (
      <div className="text-center text-gray-500 py-4">No data available</div>
    );
  }

  return (
    <div className="relative w-full">
      {showExportButton && (
        <div className="flex justify-end mb-4">
          <Button
            onClick={exportToCSV}
            className="px-4 py-2 bg-style-secondary text-white rounded transition-colors duration-200"
          >
            Export to CSV
          </Button>
        </div>
      )}
      <div className="relative w-full">
        <div className="absolute inset-y-0 -left-[50px] -right-[50px] border-t-2 border-b-2 border-mcharcoal-900 pointer-events-none"></div>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs font-inter text-mcharcoal-900 uppercase">
            <tr>
              {visibleColumns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className={`tracking-wider px-6 py-3 ${getColumnClass(column)} cursor-pointer`}
                  onClick={() => requestSort(column.key)}
                >
                  <div className="flex items-center justify-between relative">
                    <span>{column.key.replace(/_/g, " ")}</span>
                    {sortConfig.key === column.key && (
                      <span className="absolute right-0 translate-x-5">
                        {sortConfig.direction === "ascending" ? (
                          <ChevronUpIcon className="w-4 h-4 ml-1" />
                        ) : (
                          <ChevronDownIcon className="w-4 h-4 ml-1" />
                        )}
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b border-mcharcoal-400 hover:bg-morange-100"
              >
                {visibleColumns.map((column) => (
                  <td
                    key={column.key}
                    className={`px-6 py-3 ${getColumnClass(column)}`}
                  >
                    {renderCell(row, column)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { Table };
