const Badge = ({
  children = null,
  active = false,
  color = "purple",
  className = null,
  isButton = true,
  onClick = null,
}) => {
  return (
    <>
      {isButton ? (
        <button
          className={`inline-flex items-center rounded-full bg-${color}-50 px-2 py-1 text-xs font-medium text-${color}-700 ring-1 ring-inset ring-${color}-700${active ? "" : "/10"} ${className}`}
          onClick={onClick}
        >
          {children}
        </button>
      ) : (
        <span
          className={`inline-flex items-center rounded-full bg-${color}-50 px-2 py-1 text-xs font-medium text-${color}-700 ring-1 ring-inset ring-${color}-700${active ? "" : "/10"} ${className}`}
          onClick={onClick}
        >
          {children}
        </span>
      )}
    </>
  );
};

export { Badge };
