// DemographicFilters.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "../../components/layouts/Card";
import { Badge } from "../../components/text/Badge";
import { Footnote } from "../../components/text/Footnote";
import { toggleDemographicFilter } from "../../store/districtPage";

const FilterGroup = ({ name, options, footnote, category }) => {
  const dispatch = useDispatch();
  const activeFilters = useSelector((state) => state.districtPage.demographicFilters[category]);

  const handleToggle = (value) => {
    dispatch(toggleDemographicFilter({ category, value }));
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-center w-full pt-6">
        <Footnote className="mr-3">
          <span className="text-lg font-light">{name}</span>
        </Footnote>
        <div className="block mr-auto">
          {options.map((option) => (
            <Badge
              key={option.label}
              className="m-1"
              color={option.color}
              active={activeFilters.includes(option.label)}
              onClick={() => handleToggle(option.label)}
            >
              {option.label}
            </Badge>
          ))}
        </div>
      </div>
      {footnote && <Footnote>{footnote}</Footnote>}
    </div>
  );
};

export const DemographicFilters = ({ title, subtitle, filters }) => (
  <Card title={title} subtitle={subtitle}>
    {filters.map((filter) => (
      <FilterGroup
        key={filter.name}
        {...filter}
        category={filter.name.toLowerCase().replace(/&|\s/g, "")}
      />
    ))}
  </Card>
);
