import { createSlice } from "@reduxjs/toolkit";

const earlyVoteSlice = createSlice({
  name: "earlyVote",
  initialState: {
    voteType: "total",
    place: "", // Add place to the initial state
    queryString: undefined, // Add queryString to the initial state
    comparisonType: "Nothing", // Add comparisonType to the initial state
  },
  reducers: {
    setVoteType: (state, action) => {
      state.voteType = action.payload;
    },
    setPlace: (state, action) => {
      state.place = action.payload; // Add setPlace reducer
    },
    setQueryString: (state, action) => {
      state.queryString = action.payload; // Add setQueryString reducer
    },
    setComparisonType: (state, action) => {
      state.comparisonType = action.payload; // Add setComparisonType reducer
    },
  },
});

export const { setVoteType, setPlace, setQueryString, setComparisonType } =
  earlyVoteSlice.actions;
export default earlyVoteSlice.reducer;
