function Button({
  children,
  onClick,
  className = "font-medium text-slate-700 bg-morange-100 hover:bg-morange-200 hover:ring-2 hover:ring-morange-200 outline outline-2 outline-morange-200",
}) {
  return (
    <button
      onClick={onClick}
      className={`mb-4 mt-4 px-4 py-2 rounded-md text-sm flex items-center ${className}`}
    >
      {children}
    </button>
  );
}

export { Button };
