import { useState, useEffect, useRef } from "react";
import { animated, useSpring } from "@react-spring/web";
import { Text } from "@visx/text";

const AnimatedBar = ({ x, y, width, height, fill, direction = "up" }) => {
  let from = {};
  let to = {};

  if (direction === "up" || direction === "down") {
    if (direction === "up") {
      from = { y: y + height, height: 0 };
      to = { y: y, height: height };
    } else if (direction === "down") {
      from = { y: y, height: 0 };
      to = { y: y, height: height };
    }
  } else if (direction === "left" || direction === "right") {
    if (direction === "left") {
      from = { x: x + width, width: 0 };
      to = { x: x, width: width };
    } else if (direction === "right") {
      from = { x: x, width: 0 };
      to = { x: x, width: width };
    }
  }

  const springProps = useSpring({
    from: from, // Start from height 0
    to: to, // Animate to actual height
  });

  if (direction === "left" || direction === "right") {
    return (
      <animated.rect
        x={springProps.x}
        y={y}
        width={springProps.width}
        height={height}
        fill={fill}
      />
    );
  } else if (direction === "up" || direction === "down") {
    return (
      <animated.rect
        x={x}
        y={springProps.y}
        width={width}
        height={springProps.height}
        fill={fill}
      />
    );
  }
};

const AnimatedText = ({
  x,
  y,
  text,
  fill,
  width,
  fontSize = "14px",
  verticalAnchor = "middle",
  textAnchor = "middle",
  initialDelay = 300, // Delay in milliseconds for initial animation
}) => {
  const prevProps = useRef({ x, y, text });
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [opacityStyle, opacityApi] = useSpring(() => ({
    opacity: 0, // Start fully transparent
    delay: initialDelay,
  }));

  const [positionStyle, positionApi] = useSpring(() => ({
    x,
    y,
    delay: initialDelay,
  }));

  useEffect(() => {
    const animate = async () => {
      if (isInitialRender) {
        // Initial render: just fade in
        await opacityApi.start({ opacity: 1, delay: initialDelay });
        setIsInitialRender(false);
      } else if (
        prevProps.current.x !== x ||
        prevProps.current.y !== y ||
        prevProps.current.text !== text
      ) {
        // Subsequent updates: fade out, move, fade in
        await opacityApi.start({ opacity: 0 });
        await positionApi.start({ x, y });
        opacityApi.start({ opacity: 1 });
      }
    };

    animate();
    prevProps.current = { x, y, text };
  }, [x, y, text, opacityApi, positionApi, isInitialRender, initialDelay]);

  return (
    <animated.g style={positionStyle}>
      <animated.g style={opacityStyle}>
        <Text
          x={0} // We'll animate the entire group, so keep Text at 0,0
          y={0}
          textAnchor={textAnchor}
          verticalAnchor={verticalAnchor}
          fill={fill}
          fontSize={fontSize}
          // width={width}
          scaleToFit="shrink-only"
          fontFamily="Inter, sans-serif"
        >
          {text}
        </Text>
      </animated.g>
    </animated.g>
  );
};

export { AnimatedBar, AnimatedText };
