import React from "react";
import { EarlyVoteDemographicSection } from "./EarlyVoteDemographicSection";
import { Footnote } from "../../components/text/Footnote";

// Helper function to generate mock data
const generateMockData = (year, startDays, endDays, multiplier = 1) => {
  const data = [];
  let cumulativeBallots = 0;
  for (let i = startDays; i >= endDays; i--) {
    const date = new Date(year, 10, 3 - i); // November 3rd is assumed as the election day
    cumulativeBallots += (Math.floor(Math.random() * 1000) + 500) * multiplier;
    data.push({ date, cumulativeBallots });
  }
  return data;
};

const MainContent = ({ queryString }) => {
  // Generate mock data for the chart
  const mockCurrentYearData = generateMockData(2024, 60, 15);
  const mockPreviousYearData = generateMockData(2024, 60, 0, 0.9);

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        {/* <div className="">
          <p className="!text-3xl font-bold text-left">Early Voting Trend</p>
          <div className="border-b border border-slate-900 w-full col-span-12" />
          <div className="h-96">
            <AnimatedLineChart
              data={mockCurrentYearData}
              comparisonData={mockPreviousYearData}
              loading={false}
              height={400}
            />
          </div>
        </div> */}

        <EarlyVoteDemographicSection queryString={queryString} />
        <Footnote>
          Counts from Atlas by Murmuration. There may be a small reporting lag
          and counts may not yet include registrants from October.
        </Footnote>
      </div>
    </div>
  );
};

export default MainContent;
