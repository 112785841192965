import React from "react";
import { Card } from "../../components/layouts/Cards";
import { EarlyVoteDemographicSection } from "./EarlyVoteDemographicSection";
import { Footnote } from "../../components/text/Footnote";

const EarlyVoteSection = ({ queryString, comparisonQueryString }) => {
  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Early Vote</p>}
        id="early-vote"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />
            <Footnote>
              Counts from Atlas by Murmuration. There may be a small reporting
              lag and counts may not yet include registrants from October.
            </Footnote>
          </>
        }
        className="pt-4"
      />
      <EarlyVoteDemographicSection
        queryString={queryString}
        comparisonQueryString={comparisonQueryString}
      />
    </>
  );
};

export { EarlyVoteSection };
