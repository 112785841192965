import React, { useState } from "react";
import FuzzyGeoSearch from "../search/FuzzyGeoSearch";
import { ReusableCombobox } from "../forms/ReusableCombobox";
import { Button } from "../forms/Button";

const PlacesTab = ({ onSearch }) => {
  const [selectedGeography, setSelectedGeography] = useState(null);
  const [selectedGeographyLevel, setSelectedGeographyLevel] = useState(null);
  const [geographyPairs, setGeographyPairs] = useState([]);

  const geographyOptions = [
    "states",
    "counties",
    "congressional districts",
    "state lower house districts",
    "state upper house districts",
    "tracts",
    "block groups",
    "ZIP codes",
    "unified school districts",
    "elementary school districts",
    "secondary school districts",
    "census places",
  ];

  const handleGeoSelect = (geo) => {
    setSelectedGeography(geo);
  };

  const handleGeoLevelSelect = (level) => {
    setSelectedGeographyLevel(level);
  };

  const handleAddPair = () => {
    if (selectedGeography) {
      const pair = selectedGeographyLevel
        ? `${selectedGeography.name} ${selectedGeographyLevel}`
        : selectedGeography.name;
      setGeographyPairs((prev) => [...prev, pair]);
      // geography component to send to backend
      const geographyObject = {
        name: selectedGeography.name,
        geo_query: selectedGeography.geo_query,
        geography_level: selectedGeographyLevel || null,
        geography_pair: pair,
      };
      onSearch(geographyObject);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4">
        <FuzzyGeoSearch handleSelect={handleGeoSelect} className="h-10" />
        <ReusableCombobox
          items={geographyOptions}
          onSelect={(item) => handleGeoLevelSelect(item || null)}
          displayValue={(item) => (item ? item.replace(/_/g, " ") : "")}
          formatOption={(item) => item.replace(/_/g, " ")}
          className="!mt-0 h-10"
          placeholder="Select geography level (optional)"
        />
        <Button
          onClick={handleAddPair}
          className="font-medium text-slate-700 bg-morange-100 hover:bg-morange-200 hover:ring-2 hover:ring-morange-200 outline outline-2 outline-morange-200 !mt-0 !mb-0"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export { PlacesTab };
