function Title({ children, className }) {
  return (
    <div
      className={`text-4xl font-inter font-semibold tracking-tighter ${className}`}
    >
      {children}
    </div>
  );
}

export { Title };
