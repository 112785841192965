import React, { useEffect } from 'react';
import apiClient from "../utility/axios";

const HealthPage = () => {

  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        const response = await apiClient.get("/api/health");
        console.log("Server is healthy", response);
      } catch (error) {
        console.log("Server is not healthy", error);
      }
    }

    checkServerHealth();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-orange-50">
      <div className="mb-8">
        <img src="Explorer_2024_Logo_WithLargeTag_Amber.png" alt="Explorer 2024 Logo" style={{ height: '200px' }} />
      </div>
      <div>
        I am alive !!!
      </div>
    </div>
  );
}

export { HealthPage };
