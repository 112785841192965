import { Title } from "../text/Title";
import { Subtitle } from "../text/Subtitle";
import { Footnote } from "../text/Footnote";

function Card({
  colspan = 1,
  className = "",
  colspanSm = null,
  colspanMd = null,
  children,
  title = null,
  subtitle = null,
  footnote = null,
  key = null,
  id = null,
}) {
  // We cut the span in 3rd for each md, and round down.
  colspanMd = colspanMd || Math.floor(colspan / 2);
  colspanSm = 1;
  const span = `col-span-${colspanSm} md:col-span-${colspanMd} lg:col-span-${colspan}`;

  return (
    <div
      className={`flex flex-col flex-1 text-base p-2 font-inter ${span} ${className} relative`}
      key={key}
      id={id}
    >
      {title && <Title className="text-xl">{title}</Title>}
      {subtitle && <Subtitle className="text-base">{subtitle}</Subtitle>}
      {children}
      {footnote && <Footnote>{footnote}</Footnote>}
    </div>
  );
}

export { Card };
