import React, { useState, useEffect } from "react";
import { CatalogSearchBar } from "./CatalogSearchBar";
import { tableParameters } from "../../pages/search/config";

const FiltersTab = ({ activeTab, onFilterSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedCategory, setExpandedCategory] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(tableParameters);

  useEffect(() => {
    // Reset expandedCategory when the Filters tab is selected
    if (activeTab === "filters") {
      setExpandedCategory([]);
    }
  }, [activeTab]);

  const toggleCategory = (category) => {
    if (expandedCategory.includes(category)) {
      setExpandedCategory(expandedCategory.slice(0, -1)); // Go back to the previous level
    } else {
      setExpandedCategory([...expandedCategory, category]); // Go deeper into the next level
    }
  };

  const renderFilters = (categories, path = []) => {
    const currentCategory = path.reduce((acc, key) => acc[key], categories);
    const { filters, ...filteredCategory } = currentCategory;

    if (!currentCategory || Array.isArray(currentCategory)) {
      return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {currentCategory.map((filter) => (
            <div key={filter} className="flex items-center">
              <button
                onClick={() => {
                  const fullFilterName =
                    path.length > 1
                      ? `${path[path.length - 1].replace(/ /g, "_")}_${filter}`
                      : filter;
                  onFilterSelect(fullFilterName);
                }}
                className="flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
              >
                {filter
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </button>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-4 gap-3">
        {Object.keys(filteredCategory).map((subCategory) => (
          <div key={subCategory}>
            <button
              onClick={() => toggleCategory(subCategory)}
              className="flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
            >
              {tableParameters[subCategory]?.icon && (
                <div className="flex items-center justify-center w-8 h-8 text-slate-600 rounded-full">
                  {tableParameters[subCategory].icon}
                </div>
              )}
              <span className="mt-2">
                {subCategory
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </span>
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <CatalogSearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        data={tableParameters}
        paramType="filters"
        onFilter={setFilteredCategories}
      />

      {expandedCategory.length === 0 ? (
        renderFilters(filteredCategories)
      ) : (
        <div>
          <button
            onClick={() => setExpandedCategory(expandedCategory.slice(0, -1))}
            className="mb-4 px-4 py-2 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
          >
            Back to{" "}
            {expandedCategory
              .slice(-1)[0]
              .replace(/_/g, " ")
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ") || "Filter Categories"}
          </button>
          {renderFilters(filteredCategories, expandedCategory)}
        </div>
      )}
    </div>
  );
};

export { FiltersTab };
