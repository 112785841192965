import React, { useMemo } from "react";
import { Card } from "../../components/layouts/Cards";
// import { createUseBackendData } from "../../hooks/data";
import MetricDisplay from "../../components/data/MetricDisplay";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/fetchData";

const voteTypes = ["total", "by_mail", "in_person"];

const EarlyVoteContext = ({ geoQuery }) => {
  const districtInfo = useSelector((state) => state.districtPage.districtInfo);

  let earlyVoteData = [];
  let earlyVoteData7day = [];
  let earlyVoteData2020 = [];

  earlyVoteData = [
    useFetch(`early_vote/counts?vote_type=total&${geoQuery}`),
    useFetch(`early_vote/counts?vote_type=by_mail&${geoQuery}`),
    useFetch(`early_vote/counts?vote_type=in_person&${geoQuery}`),
  ];
  earlyVoteData7day = [
    useFetch(`early_vote/counts?vote_type=total&last_week=true&${geoQuery}`),
    useFetch(`early_vote/counts?vote_type=by_mail&last_week=true&${geoQuery}`),
    useFetch(
      `early_vote/counts?vote_type=in_person&last_week=true&${geoQuery}`
    ),
  ];
  earlyVoteData2020 = [
    useFetch(`g2020_early_vote/counts?vote_type=total&${geoQuery}`),
    useFetch(`g2020_early_vote/counts?vote_type=by_mail&${geoQuery}`),
    useFetch(`g2020_early_vote/counts?vote_type=in_person&${geoQuery}`),
  ];
  // const earlyVoteData = useMemo(() =>
  //   voteTypes.map((type) => useFetch(`early_vote/counts?vote_type=${type}&${geoQuery}`)),
  //   [geoQuery]
  // );

  // const earlyVoteData7day = useMemo(() =>
  //   voteTypes.map((type) => useFetch(`early_vote/counts?vote_type=${type}&last_week=true&${geoQuery}`)),
  //   [geoQuery]
  // );

  // const earlyVoteData2020 = useMemo(() =>
  //   voteTypes.map((type) => useFetch(`g2020_early_vote/counts?vote_type=${type}&${geoQuery}`)),
  //   [geoQuery]
  // );

  const formatMetricName = (type) => {
    return `Votes Cast Early ${type
      .replace(/_/g, " ")
      .replace("total", "")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")}`;
  };

  return (
    <Card title={`Early Vote in ${districtInfo.name}`}>
      <div className="grid grid-cols-1 gap-6 mt-4">
        {voteTypes.map((type, index) => (
          <MetricDisplay
            key={type}
            metric={formatMetricName(type)}
            value={earlyVoteData[index].data?.[0]?.value ?? 0}
            change7day={earlyVoteData7day[index].data?.[0]?.value ?? 0}
            value2020={earlyVoteData2020[index].data?.[0]?.value ?? 0}
            loading={
              earlyVoteData[index].loading ||
              earlyVoteData2020[index].loading ||
              earlyVoteData7day[index].loading
            }
          />
        ))}
      </div>
    </Card>
  );
};

export { EarlyVoteContext };
