// Add hover functionality, including a tooltip, to the Mapbox map

import mapboxgl from "mapbox-gl";
import { formatTitle } from "../formatText";
import { formatLargeNumber } from "../numberFormats";

const formatValue = (value, metric) => {
  if (value === undefined || value === null) return "No data";
  if (value <= 1) {
    return `${Math.round(value * 100)}%`;
  }
  return formatLargeNumber(value);
};

export const addMapHover = (map, metrics) => {
  let hoveredPolygonId = null;

  // Create a popup, but don't add it to the map yet
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    maxWidth: "none",
  });

  map.on("mousemove", "polygons-fill", (e) => {
    if (e.features.length > 0) {
      const feature = e.features[0];

      // Remove existing popup first
      popup.remove();

      if (hoveredPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: hoveredPolygonId },
          { hover: false }
        );
      }
      hoveredPolygonId = feature.properties.geoid;
      map.setFeatureState(
        { source: "polygons", id: hoveredPolygonId },
        { hover: true }
      );

      map.getCanvas().style.cursor = "pointer";

      // Create tooltip content with all metrics
      const name = feature.properties.name;
      const metricsHtml = metrics
        .map((metric) => {
          const value = feature.properties[metric];
          const formattedValue = formatValue(value, metric);
          return `
            <div class="py-2 border-b border-slate-200 last:border-b-0">
              <div class="text-xs text-slate-600 font-medium">
                ${formatTitle(metric)}
              </div>
              <div class="text-sm font-semibold text-slate-800">
                ${formattedValue}
              </div>
            </div>
          `;
        })
        .join("");

      const html = `
        <div class="text-lg font-bold text-slate-900 pb-2 mb-2 border-b border-slate-300">
          ${name}
        </div>
        <div class="space-y-1">
          ${metricsHtml}
        </div>
      `;

      popup.setLngLat(e.lngLat).setHTML(html).addTo(map);
    }
  });

  map.on("mouseleave", "polygons-fill", () => {
    if (hoveredPolygonId !== null) {
      map.setFeatureState(
        { source: "polygons", id: hoveredPolygonId },
        { hover: false }
      );
    }
    hoveredPolygonId = null;

    map.getCanvas().style.cursor = "";
    popup.remove();
  });

  // Cleanup function
  return () => {
    if (popup.isOpen()) {
      popup.remove();
    }
    map.off("mousemove", "polygons-fill");
    map.off("mouseleave", "polygons-fill");
  };
};
