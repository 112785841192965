import React from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { withParentSize } from "@visx/responsive";
import { formatLargeNumber } from "../../utility/numberFormats.js";

const { colors } = require("../../utility/colors.js");

const defaultColors = [
  colors.mblue["default"],
  colors.morange["default"],
  colors.mberry["default"],
  colors.mrose["default"],
  colors.myellow["default"],
  colors.mlime["default"],
  colors.morganizer["default"],
  colors.mviolet["default"],
];

const BasePieChart = ({
  chartData,
  height,
  width,
  parentWidth,
  parentHeight,
  isPercentage,
  color = Object.values(colors.msky).slice(3),
  loading = false,
}) => {
  let dataLabelFormatter;
  if (isPercentage) {
    dataLabelFormatter = (d) => `${(d * 100).toFixed(0)}%`;
  } else {
    dataLabelFormatter = formatLargeNumber;
  }

  // Set the width and height
  width = width || parentWidth;
  height = height || parentHeight;

  const radius = Math.min(width, height) / 4;
  const centerY = height / 2;
  const centerX = width / 2;
  // Add color to data:
  chartData.forEach((d, i) => {
    d.color = color[i * 2];
  });

  return (
    <>
      {loading && (
        <div className="relative">
          <div
            className={`w-full h-[${height}px] flex items-center justify-center absolute`}
          >
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      )}
      <svg width={width} height={height}>
        <Group top={centerY} left={centerX}>
          <Pie
            data={chartData}
            pieValue={(data) => data.value}
            fill={(data) => defaultColors[data.index]}
            outerRadius={radius}
            //   innerRadius={radius * 0.6}
            cornerRadius={3}
            //   padAngle={0.1}
          >
            {(pie) =>
              pie.arcs.map((arc, index) => {
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const averageAngle = (arc.startAngle + arc.endAngle) / 2;
                // console.log(arc.data.label);
                // console.log(arc);
                // console.log(arc.startAngle);
                // console.log(arc.endAngle);
                // console.log(averageAngle);
                // Convert to X and Y coordinates and rotate 90 degrees counter-clockwise:
                const labelX = Math.cos(-averageAngle + Math.PI / 2);
                const labelY = Math.sin(-averageAngle + Math.PI / 2);

                //   Calculate Value as a Percentage of the Total:
                const value = arc.data.value;
                const total = chartData.reduce((acc, d) => acc + d.value, 0);
                const percent = Math.round((value / total) * 100);

                // If the slice is less than 5%, don't display the label:
                const showLabel = percent > 5;

                return (
                  <g key={`arc-${index}`}>
                    <path d={pie.path(arc)} fill={arc.data.color} />
                    {showLabel && (
                      <Text
                        x={centroidX}
                        y={centroidY}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        verticalAnchor="middle"
                        fill="white"
                        className="text-sm"
                      >
                        {dataLabelFormatter(value)}
                      </Text>
                    )}
                    {showLabel && (
                      <Text
                        x={centroidX * 3}
                        y={centroidY * 3}
                        width={radius * 0.6}
                        height={radius * 0.6}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        // dominantBaseline="middle"
                        className="text-xs bg-black fill-black"
                        // scaleToFit={true}
                      >
                        {arc.data.label}
                      </Text>
                    )}
                  </g>
                );
              })
            }
          </Pie>
        </Group>
      </svg>
    </>
  );
};

const PieChart = withParentSize(BasePieChart);

export { PieChart };
