
function Grid({className="", smCols = 1, mdCols = 6, lgCols=12, gap=2, children, ...args}) {
    return (
      <div className="container mx-auto px-1 py-3">
        <div className={`grid grid-cols-${smCols} md:grid-cols-${mdCols} lg:grid-cols-${lgCols} ${className} w-full h-full gap-${gap}`} {...args} >
            {children}
        </div>
      </div>
    )
}

export { Grid };
