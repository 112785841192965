import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utility/axios";

export const fetchResults = createAsyncThunk(
  "search/fetchResults",
  async (query, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/api/search", {
        params: { query, limit: 100 },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error fetching search results."
      );
    }
  }
);

export const postDataCatalogSearch = createAsyncThunk(
  "search/postDataCatalogSearch",
  async (newParameters, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/api/data-catalog-search", {
        data: newParameters,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error in data catalog search."
      );
    }
  }
);

const initialState = {
  parameters: {
    metrics: [],
    filters: [],
    geographies: [],
    places: [],
    search: "",
  },
  results: [],
  loading: false,
  error: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setParameters: (state, action) => {
      state.parameters = {
        ...state.parameters,
        ...action.payload,
      };
    },
    resetParameters: (state) => {
      state.parameters = initialState.parameters;
    },
    clearError: (state) => {
      state.error = null;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchResults.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.results = action.payload.filter(
            (entry) => !entry.function_parameters
          );
          const functionParams = action.payload.find(
            (entry) => entry.function_parameters
          );
          if (functionParams) {
            state.parameters = functionParams;
          } else {
            state.parameters = null;
          }
        } else {
          state.results = [];
          state.parameters = null;
        }
      })
      .addCase(fetchResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postDataCatalogSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postDataCatalogSearch.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.results = action.payload.filter(
            (entry) => !entry.function_parameters
          );
          const functionParams = action.payload.find(
            (entry) => entry.function_parameters
          );
          if (functionParams) {
            state.parameters = functionParams;
          } else {
            state.parameters = null;
          }
        } else {
          state.results = [];
          state.parameters = null;
        }
      })
      .addCase(postDataCatalogSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setParameters,
  addParam,
  removeParam,
  resetParameters,
  clearError,
  setResults,
} = searchSlice.actions;
export default searchSlice.reducer;
