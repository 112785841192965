import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DataCatalogModal } from "../dataCatalog/DataCatalog";
import { useNavigate } from "react-router-dom";
import { searchSuggestions } from "../../pages/search/config";
import { postDataCatalogSearch } from "../../store/searchPage";
import {
  MagnifyingGlassIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/solid";
import { generateQueryString } from "../../utility/queryStringGenerator";

const SearchBar = ({
  queryString = null,
  useCarousel = true,
  staticPlaceholder = "Search...",
}) => {
  const [query, setQuery] = useState("");
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (useCarousel) {
      const interval = setInterval(() => {
        setCurrentSuggestionIndex(
          (prevIndex) => (prevIndex + 1) % searchSuggestions.length
        );
      }, 3000); // Change suggestion every 3 seconds

      return () => clearInterval(interval);
    }
  }, [useCarousel]);

  const handleChange = (e) => {
    setQuery(queryString || e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search-results?query=${encodeURIComponent(query)}`);
  };

  const handleApplySelection = async (newParameters) => {
    try {
      dispatch(postDataCatalogSearch(newParameters));
      const queryString = generateQueryString(newParameters);
      // Update the URL with the query string without refreshing the page
      navigate(
        `/search-results?query=${encodeURIComponent(queryString)}&fromDataCatalog=true`,
        {
          replace: true,
        }
      );
    } catch (error) {
      console.error("Error in data catalog search:", error);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="relative w-full max-w-xl h-full flex items-center"
      >
        <div className="relative w-full h-full flex items-center">
          <input
            type="text"
            value={query}
            onChange={handleChange}
            className="w-full h-16 font-inter font-lighter text-sm px-10 py-auto border border-gray-100 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-morange-200"
            placeholder={
              useCarousel
                ? searchSuggestions[currentSuggestionIndex] || "Search..."
                : staticPlaceholder
            }
          />
          <div className="absolute right-16 h-6 border-l border-gray-300"></div>{" "}
          {/* Vertical line */}
          <AdjustmentsHorizontalIcon
            className="h-5 w-5 fill-slate-400 absolute right-10 cursor-pointer"
            onClick={() => setIsCatalogOpen(true)}
          />
          <button type="submit" className="absolute right-2">
            <MagnifyingGlassIcon className="h-5 w-5 fill-slate-400 mr-1" />
          </button>
        </div>
      </form>
      <DataCatalogModal
        isOpen={isCatalogOpen}
        onClose={() => setIsCatalogOpen(false)}
        onApplySelection={handleApplySelection}
      />
    </div>
  );
};

export default SearchBar;
