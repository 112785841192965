import React from "react";
import { Table } from "./Table";

const EarlyVoteTable = ({ data }) => {
  const columns = [
    {
      key: "name",
      label: "Name",
      type: "link",
      hrefSource: "geo_query",
      hrefModifier: (geo_query) => `/early-voting?${geo_query}`,
    },
    {
      key: "registered_voters",
      label: "Registered Voters",
      type: "numeric",
    },
    {
      key: "early_vote_total",
      label: "Total Early Votes",
      type: "numeric",
    },
    {
      key: "returned_total",
      label: "Votes by Mail",
      type: "numeric",
    },
    {
      key: "early_in_person_total",
      label: "Votes in Person",
      type: "numeric",
    },
  ];

  return (
    <div className="mt-4">
      <Table data={data} columnConfig={columns} />
    </div>
  );
};

export default EarlyVoteTable;
