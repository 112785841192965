// Generate natural language query from parameters

const generateQueryString = (parameters) => {
    if (
      (!parameters.metrics || parameters.metrics.length === 0) &&
      (!parameters.filters || parameters.filters.length === 0) &&
      (!parameters.places || parameters.places.length === 0)
    ) {
      return '';
    }
  
    const metricsString =
      parameters.metrics && parameters.metrics.length > 0
        ? parameters.metrics.join(' and ').replace(/_/g, ' ')
        : 'registered voters';
    const filtersString =
      parameters.filters && parameters.filters.length > 0
        ? parameters.filters.join(' and ').replace(/_/g, ' ')
        : '';
    const locationString = parameters.places ? parameters.places.join(' vs ') : '';
    const filterPhrase = filtersString ? `for ${filtersString}` : '';
  
    return `${metricsString} ${filterPhrase} in ${locationString}`;
  };

  export { generateQueryString };
