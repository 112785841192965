// Fetch formal district name from the FastAPI backend
import apiClient from "../utility/axios";


async function fetchDistrictName(geoQuery) {
  try {
    const response = await apiClient.get(`/api/geo_name?${geoQuery}`);
    const geoName = response.data["geoid_name"];
    return geoName;
  } catch (error) {
    console.error('There was an error fetching the data!', error);
    throw error;
  }
};

export default fetchDistrictName
