// ContextBar.js
import React from "react";
import { useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import GeographyDescription from "../../components/text/GeographyDescription";
import { DemographicFilters } from "./DemographicFilters";
import { contextBarConfig } from "./config";
import { MainScoreContext } from "./MainScoreContext";
import { TurnoutScoreContext } from "./TurnoutScoreContext";
import { EarlyVoteContext } from "./EarlyVoteContext";

const ContextBar = ({ queryString }) => {
  const selectedTab = useSelector((state) => state.districtPage.selectedTab);

  const renderSection = (sectionId) => {
    const section = contextBarConfig.sections[sectionId];
    switch (section.component) {
      case "GeographyDescription":
        return <GeographyDescription geoQuery={queryString} />;
      case "DemographicFilters":
        return <DemographicFilters {...section} />;
      case "ScoreContextBar":
        return <MainScoreContext geoQuery={queryString} />;
      case "TurnoutScoreContext":
        return <TurnoutScoreContext geoQuery={queryString} />;
      case "EarlyVoteContext":
        return <EarlyVoteContext geoQuery={queryString} />;
      default:
        return null;
    }
  };

  const getVisibleSection = () => {
    switch (selectedTab) {
      case "Demographics":
        return "overview";
      case "Vote History":
        return "vote_history";
      case "Scores":
        return "scores";
      case "Turnout Scores":
        return "turnout_scores";
      case "Early Vote":
        return "early_vote";
      default:
        return null;
    }
  };

  const visibleSection = getVisibleSection();

  return (
    <aside className="sticky top-48 w-[26em] block relative items-center justify-center">
      {Object.keys(contextBarConfig.sections).map((sectionId) => (
        <Transition
          key={sectionId}
          show={visibleSection === sectionId}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="font-inter text-slate-700 absolute w-full">
            {renderSection(sectionId)}
          </div>
        </Transition>
      ))}
    </aside>
  );
};

export { ContextBar };
