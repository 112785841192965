import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "../text/Badge";
import { Title } from "../text/Title";

const colors = [
  "blue",
  "yellow",
  "green",
  "red",
  "purple",
  "pink",
  "indigo",
  "orange",
  "teal",
  "cyan",
];

const PopUpPicker = ({
  children,
  items,
  selectedItem,
  onItemSelect,
  helperText = null,
  className = "",
  itemRenderer = (item) => item,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const triggerRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectItem = (item) => {
    onItemSelect(item);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block">
      <div ref={triggerRef} onClick={handleToggle}>
        {children}
      </div>
      {isOpen && (
        <div
          ref={popupRef}
          className={`absolute z-50 min-w-64 mt-2 transform -translate-x-1/2 left-1/2 ${className}`}
        >
          <div className="relative rounded-md">
            <div className="absolute bg-style-bg w-3 h-3 border-t border-l border-gray-200 transform -translate-x-1/2 -translate-y-1/2 left-1/2 rotate-45"></div>
            <div className="absolute bg-style-bg relative z-10 p-2 rounded-md p-4">
              {helperText && (
                <div className="text-base text-black mb-4">{helperText}</div>
              )}
              <div className="flex flex-row flex-wrap gap-2">
                {items.map((option, index) => (
                  <Badge
                    key={option}
                    color={colors[index % colors.length]}
                    onClick={() => handleSelectItem(option)}
                    active={selectedItem === option}
                  >
                    <span className="tracking-normal">
                      {itemRenderer(option)}
                    </span>
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-mcharcoal-default bg-opacity-40 transition-opacity duration-300 ease-in-out"
          onClick={handleToggle}
        ></div>
      )}
    </div>
  );
};

export { PopUpPicker };
