import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

const SecureRoute = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) {
    return <div>Loading...</div>;
  } else if (!authState.isAuthenticated) {
    const currentUrl = window.location.href;
    oktaAuth.setOriginalUri(currentUrl);
    return <Navigate to="/login" />;
  }

  return children;
};

export default SecureRoute;
