import React, { useState, useCallback, useRef } from "react";
import { Card } from "../layouts/Card";
import { ControlButtons } from "../charts/ControlButtons";
import { ChartContainer } from "../charts/ChartContainer";
import { PieChart } from "../charts/PieChart";
import { downloadChartAsPng } from "../../utility/downloadChart";
import { useRandomData } from "../../hooks/data";

const PieChartCard = ({
  title,
  subtitle,
  footnote = null,
  dataHook = useRandomData,
  colspan = 1,
  width = null,
  height = 300,
  outline = false,
  className = "",
  chartProps = {},
  id = null,
  children = null,
}) => {
  const { data, loading, error, toggleDataFormat, isPercentage } = dataHook();
  const [showControls, setShowControls] = useState(false);
  const chartRef = useRef(null);

  const handleMouseEnter = useCallback(() => setShowControls(true), []);
  const handleMouseLeave = useCallback(() => setShowControls(false), []);

  const handleDownload = useCallback(() => {
    downloadChartAsPng(chartRef, `${title}-pie-chart`);
  }, [title]);

  return (
    <Card
      title={title}
      subtitle={subtitle}
      footnote={footnote}
      colspan={colspan}
      outline={outline}
      className={className}
      id={id}
    >
      {children}
      <ChartContainer
        ref={chartRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data={data}
        isPercentage={isPercentage}
        toggleDataFormat={toggleDataFormat}
      >
        {showControls && (
          <ControlButtons
            data={data}
            isPercentage={isPercentage}
            toggleDataFormat={toggleDataFormat}
            downloadAsPng={handleDownload}
          />
        )}
        <div className="flex self-center w-full">
          <PieChart
            loading={loading}
            chartData={data}
            isPercentage={isPercentage}
            width={width}
            height={height}
            {...chartProps}
          />
        </div>
      </ChartContainer>
    </Card>
  );
};

export default PieChartCard;
