import React from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
import Hotjar from "@hotjar/browser";

function App() {
  useEffect(() => {
    const siteId = 5215222;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <AppRoutes />
      </Router>
    </Provider>
  );
}

export default App;
