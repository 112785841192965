import { useState, useEffect, useCallback } from "react";
import apiClient from "../utility/axios";

const useFetch = (endpoint, defaultToPercentage = false) => {
  const [rawData, setRawData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPercentage, setIsPercentage] = useState(defaultToPercentage);

  useEffect(() => {
    const fetchData = async () => {
      if (!endpoint) {
        setLoading(false);
        setRawData([]);
        setDisplayData([]);
        return;
      }

      if (endpoint) {
        try {
          setLoading(true);
          setRawData([]);
          setDisplayData([]);
          const response = await apiClient.get(`/api/${endpoint}`);
          let result =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          setRawData(result);

          if (isPercentage) {
            setDisplayData(
              result.map((item) => ({
                ...item,
                value: item.total !== 0 ? item.value / item.total : 0,
              }))
            );
          } else {
            setDisplayData(result);
          }
        } catch (error) {
          console.error("There was an error fetching the data!", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [endpoint, isPercentage]);

  const toggleDataFormat = useCallback(() => {
    setIsPercentage((prev) => !prev);
  }, []);

  return { data: displayData, loading, error, toggleDataFormat, isPercentage };
};

export default useFetch;
