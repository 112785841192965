import React from "react";
import { demographicCategories, demographicChartTypes } from "./config";
import {
  Card,
  BarChartCard,
  PieChartCard,
} from "../../components/layouts/Cards";
import { createUseBackendData } from "../../hooks/data";

const DemographicSection = ({ queryString, comparisonQueryString }) => {
  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Demographics</p>}
        id="demographics"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />{" "}
          </>
        }
        className="pt-4"
      />
      {demographicCategories.map((category) => {
        if (demographicChartTypes[category] === "bar") {
          return (
            <BarChartCard
              key={category}
              id={category}
              title={category
                .replace(/_/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              subtitle="Among registered voters"
              endpoint={`voterfile/counts?demographic_category=${category}&${queryString}`}
              comparisonEndpoint={
                comparisonQueryString !== undefined
                  ? `voterfile/counts?demographic_category=${category}&${comparisonQueryString}`
                  : undefined
              }
              defaultToPercentage={true}
              colspan={
                category === "gender" || category === "marital_status" ? 6 : 12
              }
            />
          );
        } else if (demographicChartTypes[category] === "pie") {
          return (
            <PieChartCard
              key={category}
              id={category}
              title={category
                .replace(/_/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              subtitle="Among registered voters"
              dataHook={createUseBackendData(
                `voterfile/counts?demographic_category=${category}&${queryString}`
              )}
              colspan={12}
              footnote="Source: Atlas. Note that this data is modeled and may not be 100% accurate."
            />
          );
        }
        return null;
      })}
    </>
  );
};

export { DemographicSection };
