import React, { useState, useCallback } from "react";
import { Card } from "../layouts/Card";
import { ControlButtons } from "../charts/ControlButtons";
import { ChartContainer } from "../charts/ChartContainer";
import { HorizontalBarChart } from "../charts/HorizontalBarChart";
import { HorizontalDivergentBarChart } from "../charts/HorizontalDivergentBarChart";
import useFetch from "../../hooks/fetchData";

const TurnoutBreakdownCard = ({
  title,
  subtitle,
  footnote = null,
  endpoint,
  comparisonEndpoint,
  leanEndpoint,
  comparisonLeanEndpoint,
  colspan = 1,
  width = null,
  height = 300,
  outline = false,
  className = "",
  chartProps = {},
  id = null,
  children = null,
}) => {
  const {
    data: turnoutData,
    loading: turnoutLoading,
    error: turnoutError,
    toggleDataFormat: turnoutToggleDataFormat,
    isPercentage: turnoutIsPercentage,
  } = useFetch(endpoint, true);
  const {
    data: comparisonData = null,
    loading: comparisonLoading = false,
    error: comparisonError = null,
    toggleDataFormat: comparisonToggleDataFormat = null,
    isPercentage: comparisonIsPercentage = null,
  } = useFetch(comparisonEndpoint, true);
  const {
    data: partisanData,
    loading: partisanLoading,
    error: partisanError,
    toggleDataFormat: partisanToggleDataFormat,
    isPercentage: partisanIsPercentage,
  } = useFetch(leanEndpoint, false);
  const {
    data: comparisonPartisanData = null,
    loading: comparisonPartisanLoading = false,
    error: comparisonPartisanError = null,
    toggleDataFormat: comparisonPartisanToggleDataFormat = null,
    isPercentage: comparisonPartisanIsPercentage = null,
  } = useFetch(comparisonLeanEndpoint, false);

  const [showControls, setShowControls] = useState(false);

  const handleMouseEnter = useCallback(() => setShowControls(true), []);
  const handleMouseLeave = useCallback(() => setShowControls(false), []);

  const isLoading = turnoutLoading && comparisonLoading;
  const combinedPartisanLoading = partisanLoading && comparisonPartisanLoading;

  const hasError = turnoutError || comparisonError;
  const combinedPartisanError = partisanError || comparisonPartisanError;

  const combinedIsPercentage = turnoutIsPercentage || comparisonIsPercentage;
  const combinedPartisanIsPercentage =
    partisanIsPercentage || comparisonPartisanIsPercentage;

  const combinedToggleDataFormat = useCallback(() => {
    turnoutToggleDataFormat();
    if (comparisonToggleDataFormat) {
      comparisonToggleDataFormat();
    }
  }, [turnoutToggleDataFormat, comparisonToggleDataFormat]);

  const combinedPartisanToggleDataFormat = useCallback(() => {
    partisanToggleDataFormat();
    if (comparisonPartisanToggleDataFormat) {
      comparisonPartisanToggleDataFormat();
    }
  }, [partisanToggleDataFormat, comparisonPartisanToggleDataFormat]);

  return (
    <Card
      title={title}
      subtitle={subtitle}
      footnote={footnote}
      colspan={colspan}
      outline={outline}
      className={className}
      id={id}
    >
      {children}
      <div className="flex w-full">
        <div className="w-2/3">
          <ChartContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data={turnoutData}
            isPercentage={combinedIsPercentage}
            toggleDataFormat={combinedToggleDataFormat}
          >
            {showControls && (
              <ControlButtons
                data={turnoutData}
                isPercentage={combinedIsPercentage}
                toggleDataFormat={combinedToggleDataFormat}
              />
            )}
            <HorizontalBarChart
              loading={isLoading}
              chartData={turnoutData}
              comparisonData={comparisonData}
              isPercentage={combinedIsPercentage}
              width={width}
              height={height}
              axisTitle={
                turnoutIsPercentage ? "Turnout Rate" : "Estimated Votes"
              }
              {...chartProps}
            />
          </ChartContainer>
        </div>
        <div className="w-1/3">
          <ChartContainer
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data={partisanData}
            isPercentage={combinedPartisanIsPercentage}
            toggleDataFormat={combinedPartisanToggleDataFormat}
          >
            {showControls && (
              <ControlButtons
                data={partisanData}
                isPercentage={combinedPartisanIsPercentage}
                toggleDataFormat={combinedPartisanToggleDataFormat}
              />
            )}
            <HorizontalDivergentBarChart
              loading={combinedPartisanLoading}
              chartData={partisanData}
              comparisonData={comparisonPartisanData}
              isPercentage={combinedPartisanIsPercentage}
              labelAreaWidth={0}
              width={width}
              height={height}
              negativeLabel={
                combinedPartisanIsPercentage
                  ? "Republican Lean"
                  : "Net-Republican Votes"
              }
              positiveLabel={
                combinedPartisanIsPercentage
                  ? "Democratic Lean"
                  : "Net-Democratic Votes"
              }
              {...chartProps}
            />
          </ChartContainer>
        </div>
      </div>
    </Card>
  );
};

export default TurnoutBreakdownCard;
