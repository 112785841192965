import React, { useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid"; // Import search icon from heroicons

const CatalogSearchBar = ({
  searchTerm,
  setSearchTerm,
  data,
  paramType,
  onFilter,
}) => {
  useEffect(() => {
    const filterParams = (params, term) => {
      return params.filter((param) =>
        param.toLowerCase().includes(term.toLowerCase())
      );
    };

    const searchCategory = (categoryData, term) => {
      if (Array.isArray(categoryData)) {
        // If the category data is a flat array, filter it directly
        return filterParams(categoryData, term);
      }

      const filteredData = {};

      Object.keys(categoryData).forEach((subCategory) => {
        const params = categoryData[subCategory];

        if (Array.isArray(params)) {
          // If the params is an array, filter it directly
          const filteredParams = filterParams(params, term);
          if (filteredParams.length > 0) {
            filteredData[subCategory] = filteredParams;
          }
        } else if (typeof params === "object") {
          // If it's an object, recursively search the subcategories
          const subCategoryResults = searchCategory(params, term);
          if (Object.keys(subCategoryResults).length > 0) {
            filteredData[subCategory] = subCategoryResults;
          }
        }
      });

      return filteredData;
    };

    const filteredData = Object.keys(data).reduce((acc, category) => {
      const categoryData = data[category][paramType];

      if (!categoryData) {
        return acc; // Skip categories that don't have the paramType array
      }

      const categoryNameMatches = category
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const filteredParams = searchCategory(categoryData, searchTerm);

      if (
        categoryNameMatches ||
        (Array.isArray(filteredParams) && filteredParams.length > 0) ||
        Object.keys(filteredParams).length > 0
      ) {
        acc[category] = Array.isArray(categoryData)
          ? filteredParams
          : { ...categoryData, [paramType]: filteredParams };
      }

      return acc;
    }, {});

    onFilter(filteredData);
  }, [searchTerm, data, paramType, onFilter]);

  return (
    <form className="relative w-full max-w-xl h-full h-12 mb-8">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        className="w-full h-full min-h-[5vh] font-inter font-lighter text-sm px-10 py-auto border border-gray-100 rounded-full shadow-xl focus:outline-none focus:ring-2 focus:ring-morange-200"
      />
      <button
        type="submit"
        className="absolute left-4 top-1/2 transform -translate-y-1/2"
      >
        <MagnifyingGlassIcon className="h-4 w-4 fill-slate-400" />
      </button>
    </form>
  );
};

export { CatalogSearchBar };
