// ReusableCombobox.jsx
import { useState } from "react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";

const ReusableCombobox = ({
  items,
  selected,
  onSelect,
  displayValue = (item) => item,
  formatOption = (item) => item,
  className = "",
  placeholder = "",
  fillColor = "bg-style-surface",
}) => {
  const [query, setQuery] = useState("");

  const filteredItems =
    query === ""
      ? items
      : items.filter((item) =>
          formatOption(item).toLowerCase().includes(query.toLowerCase())
        );

  return (
    <Combobox value={selected} onChange={onSelect} onClose={() => setQuery("")}>
      <div className={`relative font-inter w-full ${className}`}>
        <div className="relative cursor-pointer">
          <ComboboxInput
            className={`font-inter text-style-text-dark shadow-md text-sm rounded-lg w-full ${fillColor} border-none py-1.5 pr-8 pl-3 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-morange-200 h-10 cursor-pointer`}
            displayValue={displayValue}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            onClick={(e) => {
              // Prevent input focus when clicking to open
              e.preventDefault();
              e.target.blur();
              // Find and click the button programmatically
              e.target.nextElementSibling.click();
            }}
          />
          <ComboboxButton className="absolute inset-0 w-full h-full opacity-0" />
          <ChevronDownIcon className="absolute right-2.5 top-1/2 -translate-y-1/2 size-4 fill-slate-700 pointer-events-none" />
        </div>
        <ComboboxOptions
          anchor="bottom"
          transition
          className={`absolute z-50 w-[var(--input-width)] max-h-48 overflow-auto rounded-xl border border-morange-100/5 ${fillColor} p-1 [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0`}
        >
          {filteredItems.map((item) => (
            <ComboboxOption
              key={item}
              value={item}
              className="group flex cursor-default gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-morange-200 items-start"
            >
              <CheckIcon className="invisible size-4 fill-slate-700 group-data-[selected]:visible font-inter " />
              {formatOption(item)}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};

export { ReusableCombobox };
