import React, { useMemo, useState, useEffect } from "react";
import { Text } from "@visx/text";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { animated, useSpring } from "@react-spring/web";
import { withParentSize } from "@visx/responsive";
import { hatch } from "ldrs";
import { formatLargeNumber } from "../../utility/numberFormats.js";
import { AnimatedBar, AnimatedText } from "./Bars.js";

hatch.register();

const { colors } = require("../../utility/colors.js");

const verticalMargin = 120;

function CoreBarChart({
  chartData = null,
  comparisonData,
  width = null,
  height = null,
  parentHeight = null,
  parentWidth = null,
  color = colors.chartColors["primary"],
  comparisonColor = colors.chartColors["secondary"],
  dataLabelFormatter = null,
  isPercentage = false,
  loading = false,
  xPadding = 0.4,
  valueMin = null,
  valueMax = null,
  valueFontSize = 14,
}) {
  if (!dataLabelFormatter) {
    if (isPercentage) {
      dataLabelFormatter = (d) => `${(d * 100).toFixed(0)}%`;
    } else {
      dataLabelFormatter = formatLargeNumber;
    }
  }
  // Set the width and height
  width = width || parentWidth;
  height = height || parentHeight;

  // Set the margins
  const xMax = width;
  const yMax = height - verticalMargin;

  // Adjust padding if comparison bar chart:
  xPadding = comparisonData ? xPadding / 2 : xPadding;

  // Create the x and y scales
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        domain: chartData.map((d) => d.label),
        padding: xPadding,
      }),
    [xMax, chartData, comparisonData]
  );

  // Calculate the min/max across all chart and comparison (if exists) data
  if (comparisonData.length > 0) {
    const comparisonDataValues = comparisonData.map((d) => d.value);
    const chartDataValues = chartData.map((d) => d.value);
    valueMin = valueMin || 0;
    valueMax =
      valueMax || Math.max(...comparisonDataValues, ...chartDataValues);
  } else {
    valueMin = valueMin || 0;
    valueMax = valueMax || Math.max(...chartData.map((d) => d.value));
  }

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [valueMin, valueMax + 0.0001],
      }),
    [yMax, chartData, comparisonData]
  );

  // Determine BarWidth and Offsets:
  const bandWidth = xScale.bandwidth() < 100 ? xScale.bandwidth() : 100;
  const barWidth = comparisonData.length > 0 ? bandWidth / 2 : bandWidth;
  const residualWidth = xScale.bandwidth() - bandWidth;

  return width < 10 || height < 10 ? null : (
    <>
      {loading && (
        <div className="relative">
          <div
            className={`w-full h-[${height}px] flex items-center justify-center absolute`}
          >
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      )}
      <svg width={width} height={height} className="visx-chart-svg">
        <Group top={verticalMargin / 2}>
          {chartData.map((d, i) => {
            if (!d.value) return null;
            const barHeight = yMax - yScale(d.value);
            const barX = xScale(d.label) + residualWidth / 2;
            const barY = yMax - barHeight;
            return (
              <React.Fragment key={`bar-${d.label}`}>
                <AnimatedBar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={color}
                />
                <AnimatedText
                  x={
                    comparisonData
                      ? barX + barWidth / 2 - barWidth * 0
                      : barX + barWidth / 2
                  } // Center the label
                  y={barY - 10} // Position Label slightly above the bar.
                  text={dataLabelFormatter(d.value)}
                  fill={colors.mcharcoal["700"]}
                  fontSize={valueFontSize}
                  width={barWidth / (1 - xPadding)}
                >
                  {dataLabelFormatter(d.value)}
                </AnimatedText>
              </React.Fragment>
            );
          })}
          {comparisonData.length > 0 &&
            // Map over the labels in chartData and render a bar if its in comparisonData:
            chartData.map((d, i) => {
              if (!d.value) return null;
              const comparisonDatum = comparisonData.find(
                (cd) => cd.label === d.label
              );
              if (comparisonDatum) {
                const barHeight = yMax - yScale(comparisonDatum.value);
                const barX =
                  xScale(comparisonDatum.label) + barWidth + residualWidth / 2;
                const barY = yMax - barHeight;
                return (
                  <React.Fragment
                    key={`bar-${comparisonDatum.label}-comparison`}
                  >
                    <AnimatedBar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      // fill={comparisonColor[i % comparisonColor.length]}
                      fill={comparisonColor}
                    />
                    <AnimatedText
                      x={barX + barWidth / 2}
                      y={barY - 10}
                      text={dataLabelFormatter(comparisonDatum.value)}
                      fill={colors.mcharcoal["700"]}
                      fontSize={valueFontSize}
                      width={barWidth / (1 - xPadding)}
                    >
                      {dataLabelFormatter(comparisonDatum.value)}
                    </AnimatedText>
                  </React.Fragment>
                );
              }
            })}
        </Group>
        <AxisBottom
          top={yMax + verticalMargin / 2}
          scale={xScale}
          stroke={colors.mcharcoal["700"]}
          strokeWidth={2}
          tickComponent={(props) => {
            const { x, y, formattedValue, opacity } = props;
            return (
              <Text
                x={x}
                y={y}
                width={xScale.bandwidth()}
                fill={colors.mcharcoal["700"]}
                fontSize={14}
                textAnchor="middle"
                verticalAnchor="start"
                className="text-xs"
                opacity={opacity}
                fontFamily="Inter, sans-serif" // Explicitly set font family
              >
                {formattedValue}
              </Text>
            );
          }}
        />
      </svg>
    </>
  );
}

const BarChart = withParentSize(CoreBarChart);
export default BarChart;
export { BarChart };
