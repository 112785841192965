// chartUtils.js

/**
 * Downloads a chart as a high-resolution PNG with the Murmuration watermark
 * @param {React.RefObject} chartRef - Reference to the chart container
 * @param {string} fileName - Name for the downloaded file
 * @param {Object} [options] - Additional options for watermark
 * @param {number} [scaleFactor=2] - Scale factor for increasing resolution
 */
export const downloadChartAsPng = (
  chartRef,
  fileName,
  options = {},
  scaleFactor = 2
) => {
  const {
    position = "bottom-right",
    padding = 10,
    transparency = 1,
    watermarkWidth = 25,
    extraWatermarkPadding = 40,
  } = options;
  const svg = chartRef.current?.querySelector(".visx-chart-svg");
  if (!svg) {
    console.error("SVG element not found");
    return;
  }

  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const svgSize = svg.getBoundingClientRect();

  canvas.width = svgSize.width * scaleFactor;
  canvas.height =
    svgSize.height * scaleFactor + extraWatermarkPadding * scaleFactor;

  ctx.scale(scaleFactor, scaleFactor);

  const img = new Image();
  const watermark = new Image();

  img.onload = () => {
    // Draw the original chart image without offset
    ctx.drawImage(img, 0, 0);

    // Load and draw watermark
    watermark.onload = () => {
      const watermarkTargetWidth = (watermarkWidth / 100) * canvas.width;
      const watermarkAspectRatio = watermark.width / watermark.height;
      const watermarkTargetHeight = watermarkTargetWidth / watermarkAspectRatio;

      let x, y;
      switch (position) {
        case "top-right":
          x = canvas.width - watermarkTargetWidth - padding * scaleFactor;
          y = padding * scaleFactor;
          break;
        case "top-left":
          x = padding * scaleFactor;
          y = padding * scaleFactor;
          break;
        case "bottom-right":
          x = canvas.width - watermarkTargetWidth - padding * scaleFactor;
          y = canvas.height - watermarkTargetHeight - padding * scaleFactor;
          break;
        case "bottom-left":
          x = padding * scaleFactor;
          y = canvas.height - watermarkTargetHeight - padding * scaleFactor;
          break;
        default:
          x = padding * scaleFactor;
          y = canvas.height - watermarkTargetHeight - padding * scaleFactor;
      }

      ctx.globalAlpha = transparency;
      ctx.drawImage(
        watermark,
        x / scaleFactor,
        y / scaleFactor,
        watermarkTargetWidth / scaleFactor,
        watermarkTargetHeight / scaleFactor
      );
      ctx.globalAlpha = 1.0;

      // Ensure Inter font is loaded and set
      document.fonts.load(`${12 * scaleFactor}px Inter`).then(() => {
        ctx.font = `${12 * scaleFactor}px Inter`;

        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `${fileName}.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
        console.log(
          `High-resolution PNG with Murmuration watermark download initiated for ${fileName}`
        );
      });
    };

    watermark.onerror = (error) => {
      console.error("Error loading Murmuration watermark image", error);
    };

    // Set the source to the attribution in the public folder
    watermark.src = "/DataAttribution.png";
  };

  img.onerror = (error) => {
    console.error("Error loading image", error);
  };

  img.src =
    "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
};
