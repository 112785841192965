import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import MainContent from "./MainContent";
import fetchDistrictName from "../../utility/fetchDistrictName";
import fetchRegisteredVoters from "../../utility/fetchRegisteredVoters";
import { useDispatch, useSelector } from "react-redux";
import { setQueryString, setPlace } from "../../store/earlyVote";
import { newtonsCradle } from "ldrs";
import useFetch from "../../hooks/fetchData";
import EarlyVoteTable from "../../components/tables/EarlyVoteTable";
import { EarlyVoteDemographicSection } from "./EarlyVoteDemographicSection";

newtonsCradle.register();

const EarlyVotePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryString = useSelector((state) => state.earlyVote.queryString);
  const place = useSelector((state) => state.earlyVote.place);

  const [numRegisteredVoters, setNumRegisteredVoters] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isQueryStringReady, setIsQueryStringReady] = useState(false);
  const [showPercent, setShowPercent] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamsObj = {};
    queryParams.forEach((value, key) => {
      queryParamsObj[key] = value;
    });
    const newQueryString = new URLSearchParams(queryParamsObj).toString();
    dispatch(setQueryString(newQueryString));
    setIsQueryStringReady(true);
  }, [location.search, dispatch]);

  useEffect(() => {
    if (queryString !== undefined) {
      navigate(
        {
          pathname: location.pathname,
          search: queryString ? `?${queryString}` : "",
        },
        { replace: true }
      );
    }
  }, [queryString, navigate, location.pathname]);

  useEffect(() => {
    async function fetchData() {
      if (!isQueryStringReady) return;

      setIsLoading(true);
      const name = await fetchDistrictName(queryString);
      dispatch(setPlace(name));

      const registeredVoters = await fetchRegisteredVoters(queryString);
      setNumRegisteredVoters(registeredVoters);

      setIsLoading(false);
    }

    fetchData();
  }, [queryString, dispatch, isQueryStringReady]);

  const { data: earlyVoteData, loading: earlyVoteDataLoading } = useFetch(
    isQueryStringReady
      ? `early_vote/counts?vote_type=total&${queryString}`
      : null,
    false
  );

  const { data: earlyVoteData7day, loading: earlyVoteData7dayLoading } =
    useFetch(
      isQueryStringReady
        ? `early_vote/counts?vote_type=total&last_week=true&${queryString}`
        : null,
      false
    );

  const { data: earlyVoteData2020, loading: earlyVoteData2020Loading } =
    useFetch(
      isQueryStringReady
        ? `g2020_early_vote/counts?vote_type=total&${queryString}`
        : null,
      false
    );

  const { data: earlyAbsenteeData, loading: earlyAbsenteeDataLoading } =
    useFetch(
      isQueryStringReady
        ? `early_vote/counts?vote_type=by_mail&${queryString}`
        : null,
      false
    );

  const { data: earlyAbsenteeData7day, loading: earlyAbsenteeData7dayLoading } =
    useFetch(
      isQueryStringReady
        ? `early_vote/counts?vote_type=by_mail&last_week=true&${queryString}`
        : null,
      false
    );

  const { data: earlyAbsenteeData2020, loading: earlyAbsenteeData2020Loading } =
    useFetch(
      isQueryStringReady
        ? `g2020_early_vote/counts?vote_type=by_mail&${queryString}`
        : null,
      false
    );

  const { data: earlyInPersonData, loading: earlyInPersonDataLoading } =
    useFetch(
      isQueryStringReady
        ? `early_vote/counts?vote_type=in_person&${queryString}`
        : null,
      false
    );

  const { data: earlyInPersonData7day, loading: earlyInPersonData7dayLoading } =
    useFetch(
      isQueryStringReady
        ? `early_vote/counts?vote_type=in_person&last_week=true&${queryString}`
        : null,
      false
    );

  const { data: earlyInPersonData2020, loading: earlyInPersonData2020Loading } =
    useFetch(
      isQueryStringReady
        ? `g2020_early_vote/counts?vote_type=in_person&${queryString}`
        : null,
      false
    );

  const { data: usMetrics, loading: usMetricsLoading } = useFetch(
    queryString === "" ? `early_vote/vote_counts_by_location` : null,
    false
  );

  if (isLoading || usMetricsLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-style-bg">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
      </div>
    );
  }

  if (!queryString) {
    // Render landing page if no query string
    return (
      <div className="bg-style-bg min-h-screen">
        <Header
          breadcrumbs={[{ name: "United States", href: "/early-voting" }]}
          registeredVoters={numRegisteredVoters}
          earlyVoteData={earlyVoteData}
          earlyVoteData2020={earlyVoteData2020}
          earlyVoteData7day={earlyVoteData7day}
          earlyVoteDataLoading={earlyVoteDataLoading}
          earlyVoteData7dayLoading={earlyVoteData7dayLoading}
          earlyAbsenteeData={earlyAbsenteeData}
          earlyAbsenteeData2020={earlyAbsenteeData2020}
          earlyAbsenteeData7day={earlyAbsenteeData7day}
          earlyAbsenteeDataLoading={earlyAbsenteeDataLoading}
          earlyAbsenteeData7dayLoading={earlyAbsenteeData7dayLoading}
          earlyInPersonData={earlyInPersonData}
          earlyInPersonData2020={earlyInPersonData2020}
          earlyInPersonData7day={earlyInPersonData7day}
          earlyInPersonDataLoading={earlyInPersonDataLoading}
          earlyInPersonData7dayLoading={earlyInPersonData7dayLoading}
        />
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="min-w-[64rem] mx-auto mb-8">
            <EarlyVoteTable data={usMetrics} />
          </div>
          <EarlyVoteDemographicSection
            queryString=""
            comparisonQueryString=""
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-style-bg min-h-screen">
      <Header
        breadcrumbs={[{ name: place, href: `/early-voting?${queryString}` }]}
        registeredVoters={numRegisteredVoters}
        earlyVoteData={earlyVoteData}
        earlyVoteData2020={earlyVoteData2020}
        earlyVoteData7day={earlyVoteData7day}
        earlyVoteDataLoading={earlyVoteDataLoading}
        earlyVoteData7dayLoading={earlyVoteData7dayLoading}
        earlyAbsenteeData={earlyAbsenteeData}
        earlyAbsenteeData2020={earlyAbsenteeData2020}
        earlyAbsenteeData7day={earlyAbsenteeData7day}
        earlyAbsenteeDataLoading={earlyAbsenteeDataLoading}
        earlyAbsenteeData7dayLoading={earlyAbsenteeData7dayLoading}
        earlyInPersonData={earlyInPersonData}
        earlyInPersonData2020={earlyInPersonData2020}
        earlyInPersonData7day={earlyInPersonData7day}
        earlyInPersonDataLoading={earlyInPersonDataLoading}
        earlyInPersonData7dayLoading={earlyInPersonData7dayLoading}
      />
      <main>
        <MainContent queryString={queryString} />
      </main>
    </div>
  );
};

export { EarlyVotePage };
