import { configureStore } from "@reduxjs/toolkit";
import districtPageReducer from "./districtPage";
import searchPageReducer from "./searchPage";
import earlyVoteReducer from "./earlyVote"; // Use the combined earlyVote reducer

export const store = configureStore({
  reducer: {
    districtPage: districtPageReducer,
    search: searchPageReducer,
    earlyVote: earlyVoteReducer, // No need for separate place reducer
  },
});
