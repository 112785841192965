import { Text } from "@visx/text";
import { withParentSize } from "@visx/responsive";
import { formatLargeNumber } from "../../utility/numberFormats";
import { Group } from "@visx/group";
import { hatch } from "ldrs";

hatch.register();

const CoreStatBox = ({
  label,
  value,
  height,
  width,
  parentHeight,
  parentWidth,
  ratio = 0.5,
  valueFontSize = "3rem",
  labelFontSize = null,
  dataLabelFormatter = null,
  loading = false,
  isPercentage = false,
}) => {
  if (!dataLabelFormatter) {
    if (isPercentage) {
      dataLabelFormatter = (d) => `${(d * 100).toFixed(0)}%`;
    } else {
      dataLabelFormatter = formatLargeNumber;
    }
  }

  // Set the width and height
  height = height || parentHeight;
  width = width || parentWidth;

  return (
    <>
      <svg height={height} width={width} className="">
        <Text
          x={width * 0.9}
          y={height * ratio}
          width={width * 0.8}
          textAnchor="end"
          verticalAnchor="end"
          scaleToFit={"shrink-only"}
          className="font-inter fill-mcharcoal-900"
          style={{ fontSize: valueFontSize }}
        >
          {dataLabelFormatter(value)}
        </Text>
        <Text
          x={width * 0.9}
          y={height * ratio + height * 0.1}
          width={width * 0.7}
          textAnchor="end"
          verticalAnchor="start"
          className="font-inter fill-mcharcoal-500 text-lg"
          style={{ fontSize: labelFontSize }}
        >
          {label}
        </Text>
      </svg>
    </>
  );
};

const StatBox = withParentSize(CoreStatBox);

const CoreStatBoxes = ({
  chartData,
  height,
  width,
  parentHeight,
  parentWidth,
  ratio = 0.5,
  labelFontSize = null,
  valueFontSize = "3rem",
  dataLabelFormatter = null,
  loading = false,
  isPercentage = false,
}) => {
  height = height || parentHeight;
  width = width || parentWidth;

  const n = chartData.length;
  width = width / n;

  return width < 10 || height < 10 ? null : (
    <>
      {loading && (
        <div className="relative">
          <div
            className={`w-full h-[${height}px] flex items-center justify-center absolute`}
          >
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      )}
      {chartData && (
        <svg height={height} width={width * n}>
          {chartData.map((stat, index) => {
            const border = index === 0 ? "" : "border-l";

            return (
              <Group left={width * index} className={border}>
                <CoreStatBox
                  key={index}
                  label={stat.label}
                  value={stat.value}
                  height={height}
                  width={width}
                  ratio={ratio}
                  labelFontSize={labelFontSize}
                  valueFontSize={valueFontSize}
                  dataLabelFormatter={dataLabelFormatter}
                  loading={loading}
                  isPercentage={isPercentage}
                />
              </Group>
            );
          })}
        </svg>
      )}
    </>
  );
};

const StatBoxes = withParentSize(CoreStatBoxes);

export { StatBox, StatBoxes };
