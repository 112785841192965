import React, { useState, useCallback, useMemo, memo } from "react";

const ControlButtons = memo(
  ({ data, isPercentage, toggleDataFormat, downloadAsPng }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      const headers = ["Label", isPercentage ? "Proportion" : "Value"];
      const dataRows = data.map((item) => [
        item.label,
        isPercentage ? item.value.toFixed(2) : item.value.toString(),
      ]);
      const allRows = [headers, ...dataRows];
      const tsvContent = allRows.map((row) => row.join("\t")).join("\n");

      navigator.clipboard.writeText(tsvContent).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };

    return (
      <div className="absolute top-2 right-2 flex space-x-2">
        <button
          className="text-lg font-inter text-xl text-slate-700 hover:text-slate-900 rounded-full"
          onClick={toggleDataFormat}
          title={isPercentage ? "Switch to counts" : "Switch to percentages"}
        >
          {isPercentage ? "＃" : "⁒"}
        </button>
        <button
          className="text-lg font-overpass text-slate-700 hover:text-slate-900 rounded-full"
          onClick={handleCopy}
          title="Copy data to clipboard"
        >
          {copied ? (
            "✓"
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
              />
            </svg>
          )}
        </button>
        <button
          className="text-lg font-overpass text-slate-700 hover:text-slate-900 rounded-full"
          onClick={downloadAsPng}
          title="Download as PNG"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </button>
      </div>
    );
  }
);

export { ControlButtons };
