import { Card } from "../../components/layouts/Card";
import { useSelector, useDispatch } from "react-redux";
import { toggleSelectedScore } from "../../store/districtPage";
import { scores, scoreDescriptions } from "./config";
import { ReusableCombobox } from "../../components/forms/ReusableCombobox";

const MainScoreContext = ({ title, subtitle, filters }) => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.districtPage.scores.selected);

  const handleSelect = (score) => {
    dispatch(toggleSelectedScore({ score }));
  };

  const formatScoreOption = (score) => {
    return `${score
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")} Score`;
  };

  return (
    <Card title="Scores" subtitle="Atlas Scores">
      <ReusableCombobox
        items={scores}
        selected={selected}
        onSelect={handleSelect}
        displayValue={formatScoreOption}
        formatOption={formatScoreOption}
      />
      <div className="mt-6">
        {/* If Score description exists, render it: */}
        {scoreDescriptions[selected] && (
          <p className="text-base text-slate-700">
            {scoreDescriptions[selected]}
          </p>
        )}
      </div>
    </Card>
  );
};

export { MainScoreContext };
