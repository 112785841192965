// Fetch registered voter count from the FastAPI backend
import apiClient from "../utility/axios";

async function fetchRegisteredVoters(geoQuery) {
  try {
    const response = await apiClient.get(
      `/api/voterfile/counts?demographic_category=topline&${geoQuery}`
    );
    const registeredVoters = response.data[0]["total"];
    return parseFloat(registeredVoters).toLocaleString();
  } catch (error) {
    console.error("There was an error fetching the data!", error);
    throw error;
  }
}

export default fetchRegisteredVoters;
