module.exports.colors = {
  myellow: {
    default: "#ffce61",
    50: "#fff9eb",
    100: "#fff0c6",
    200: "#ffde88",
    300: "#ffce61",
    400: "#ffb020",
    500: "#f98d07",
    600: "#dd6602",
    700: "#b74606",
    800: "#94350c",
    900: "#7a2d0d",
    950: "#461502",
  },
  morange: {
    default: "#e88421",
    50: "#fef8ec",
    100: "#fbebca",
    200: "#f6d591",
    300: "#f2b957",
    400: "#eea031",
    500: "#e88421",
    600: "#cd5e12",
    700: "#aa4013",
    800: "#8a3316",
    900: "#722b15",
    950: "#411307",
  },
  mberry: {
    default: "#ea638a",
    50: "#fef2f4",
    100: "#fde6eb",
    200: "#fad1db",
    300: "#f7aabd",
    400: "#f17b9a",
    500: "#ea638a",
    600: "#d32b64",
    700: "#b21e53",
    800: "#951c4c",
    900: "#801b46",
    950: "#470a22",
  },
  mrose: {
    default: "#af2758",
    50: "#fcf3f8",
    100: "#fae9f2",
    200: "#f7d3e5",
    300: "#f2afcf",
    400: "#e87eaf",
    500: "#dd5792",
    600: "#cb3771",
    700: "#af2758",
    800: "#912349",
    900: "#792240",
    950: "#490e22",
  },
  mlime: {
    default: "#9dd848",
    50: "#f5fce9",
    100: "#eaf8cf",
    200: "#d5f1a5",
    300: "#b7e670",
    400: "#9dd848",
    500: "#7cbd25",
    600: "#5f961a",
    700: "#497318",
    800: "#3c5b19",
    900: "#344e19",
    950: "#192b08",
  },
  mforest: {
    default: "#4c662d",
    50: "#f5f8ed",
    100: "#e7f0d7",
    200: "#d1e2b4",
    300: "#b2ce88",
    400: "#96b962",
    500: "#789e44",
    600: "#5c7d33",
    700: "#4c662d",
    800: "#3b4e26",
    900: "#344324",
    950: "#19240f",
  },
  mevergreen: {
    default: "#2d4f3b",
    50: "#f2f7f3",
    100: "#dfece1",
    200: "#c2d8c7",
    300: "#99bca3",
    400: "#6c9b7c",
    500: "#4c7d5e",
    600: "#386349",
    700: "#2d4f3b",
    800: "#254030",
    900: "#1f3528",
    950: "#111d16",
  },
  msky: {
    default: "#61cbd6",
    50: "#effbfc",
    100: "#d6f5f7",
    200: "#b3eaee",
    300: "#7edae2",
    400: "#61cbd6",
    500: "#26a4b4",
    600: "#238597",
    700: "#226b7c",
    800: "#245966",
    900: "#224b57",
    950: "#11313b",
  },
  mblue: {
    default: "#157787",
    50: "#edfefe",
    100: "#d2fbfb",
    200: "#aaf5f7",
    300: "#6fecf1",
    400: "#2dd9e3",
    500: "#12bcc8",
    600: "#1296a8",
    700: "#157787",
    800: "#1a6170",
    900: "#1a515f",
    950: "#0b3641",
  },
  mviolet: {
    default: "#8282d1",
    50: "#f2f4fb",
    100: "#e7ebf8",
    200: "#d3d8f2",
    300: "#b8c0e9",
    400: "#9ca1dd",
    500: "#8282d1",
    600: "#716ac1",
    700: "#6159a9",
    800: "#4f4a89",
    900: "#43416e",
    950: "#282640",
  },
  morganizer: {
    default: "#27275b",
    50: "#f1f3fd",
    100: "#e0e5f9",
    200: "#c8d2f5",
    300: "#a3b5ed",
    400: "#778ee3",
    500: "#576bda",
    600: "#434ecd",
    700: "#393dbc",
    800: "#343499",
    900: "#2e317a",
    950: "#27275b",
  },
  mcharcoal: {
    default: "#333333",
    50: "#f6f6f6",
    100: "#e7e7e7",
    200: "#d1d1d1",
    300: "#b0b0b0",
    400: "#888888",
    500: "#6d6d6d",
    600: "#5d5d5d",
    700: "#4f4f4f",
    800: "#454545",
    900: "#333333",
    950: "#262626",
  },
  chartColors: {
    primary: "#77abbd",
    secondary: "#1d3557",
  },
  style: {
    bg: "#F8F4EF",
    primary: "#AA4013",
    secondary: "#29687D",
    surface: "#FFFFFF",
    border: "#EEEEEE",
    green: "#61714D",
    brown: "#51392D",
    maroon: "#580200",
    "text-default": "#888888",
    "text-dark": "#222222",
    "text-light": "CECED0",
  },
};
