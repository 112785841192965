import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronRightIcon, GlobeAmericasIcon } from "@heroicons/react/20/solid";
import { Title } from "../../components/text/Title";
import MetricDisplay from "../../components/data/MetricDisplay";
import { useDispatch, useSelector } from "react-redux";
import { setPlace, setQueryString } from "../../store/earlyVote"; // Update import path
import FuzzyGeoSearch from "../../components/search/FuzzyGeoSearch";

function Header({
  breadcrumbs,
  registeredVoters,
  earlyVoteData,
  earlyVoteDataLoading,
  earlyVoteData2020,
  earlyVoteData2020Loading,
  earlyVoteData7day,
  earlyVoteData7dayLoading,
  earlyAbsenteeData,
  earlyAbsenteeDataLoading,
  earlyAbsenteeData2020,
  earlyAbsenteeData2020Loading,
  earlyAbsenteeData7day,
  earlyAbsenteeData7dayLoading,
  earlyInPersonData,
  earlyInPersonDataLoading,
  earlyInPersonData2020,
  earlyInPersonData2020Loading,
  earlyInPersonData7day,
  earlyInPersonData7dayLoading,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const place = useSelector((state) => state.earlyVote.place); // Update selector path

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePlaceSelect = (selectedPlace) => {
    dispatch(setPlace(selectedPlace.name));
    dispatch(setQueryString(selectedPlace.geo_query));
  };

  return (
    <header className="bg-style-bg font-inter">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div>
                <Link
                  to="/early-voting"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <GlobeAmericasIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">USA</span>
                </Link>
              </div>
            </li>
            {breadcrumbs.map((item, index) => (
              <li key={item.name}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={item.href}
                    className={`ml-4 text-sm font-medium p-2 ${
                      location.pathname === item.href
                        ? "text-morange-700 font-semibold"
                        : "text-gray-500 hover:text-gray-700"
                    }`}
                    aria-current={
                      location.pathname === item.href ? "page" : undefined
                    }
                  >
                    {item.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="flex justify-between items-center mt-2">
          {place && (
            <Title className="text-4xl">
              <span className="font-light">Early Vote in </span>{" "}
              <span className="text-chartColors-primary">{place}</span>
            </Title>
          )}
          <div className="ml-auto min-w-96">
            <FuzzyGeoSearch
              placeholder="Search place"
              handleSelect={handlePlaceSelect}
            />
          </div>
        </div>
        {registeredVoters && (
          <p className="text-sm text-gray-500 mt-1">
            {formatNumber(registeredVoters)} registered voters
          </p>
        )}

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
          <MetricDisplay
            metric="Votes Cast"
            value={earlyVoteData.length > 0 ? earlyVoteData[0].value : 0}
            change7day={
              earlyVoteData7day.length > 0 ? earlyVoteData7day[0].value : 0
            }
            value2020={
              earlyVoteData2020.length > 0 ? earlyVoteData2020[0].value : 0
            }
            loading={earlyVoteDataLoading}
          />
          <MetricDisplay
            metric="In-Person Votes"
            value={
              earlyInPersonData.length > 0 ? earlyInPersonData[0].value : 0
            }
            change7day={
              earlyInPersonData7day.length > 0
                ? earlyInPersonData7day[0].value
                : 0
            }
            value2020={
              earlyInPersonData2020.length > 0
                ? earlyInPersonData2020[0].value
                : 0
            }
            loading={earlyInPersonDataLoading}
          />
          <MetricDisplay
            metric="Absentee Ballots"
            value={
              earlyAbsenteeData.length > 0 ? earlyAbsenteeData[0].value : 0
            }
            change7day={
              earlyAbsenteeData7day.length > 0
                ? earlyAbsenteeData7day[0].value
                : 0
            }
            value2020={
              earlyAbsenteeData2020.length > 0
                ? earlyAbsenteeData2020[0].value
                : 0
            }
            loading={earlyAbsenteeDataLoading}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
