import React from "react";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/20/solid";
import { formatLargeNumber } from "../../utility/numberFormats";
import { hatch } from "ldrs";

hatch.register();

/**
 * MetricDisplay component to display a metric with its value, change over 7 days, and 2020 value.
 *
 * @param {Object} props - Component props
 * @param {string} props.metric - The name of the metric
 * @param {number} props.value - The current value of the metric
 * @param {number} props.change7day - The change in the metric over the last 7 days
 * @param {number} props.value2020 - The value of the metric in 2020
 * @returns {JSX.Element} The rendered component
 */
const MetricDisplay = ({
  metric,
  value,
  change7day,
  value2020,
  loading = false,
}) => {
  const changeValue = change7day;
  const isPositive = changeValue >= 0;
  const changeColor = isPositive ? "text-green-600" : "text-red-600";
  const ChangeIcon = isPositive ? ArrowUpIcon : ArrowDownIcon;
  if (loading) {
    return (
      <div className="font-inter relative">
        <div className="w-full h-[1.8rem] flex items-center justify-center mb-4">
          <l-hatch size="32" color="#334155"></l-hatch>
        </div>
      </div>
    );
  }

  return (
    <div className="font-inter">
      {changeValue != undefined && (
        <div className={`${changeColor} flex items-center`}>
          <ChangeIcon className="w-5 h-5 inline-block" />
          <span className={`text-base font-bold tracking-wide`}>
            {formatLargeNumber(changeValue)}
          </span>
          <span className="text-sm font-light tracking-tight ml-1">
            in last 7 days
          </span>
        </div>
      )}
      <span className="text-3xl font-bold">{formatLargeNumber(value)}</span>{" "}
      <span className="text-2xl font-light tracking-tight">{metric}</span>
      {value2020 != undefined && (
        <div className="text-gray-600">
          <span className="text-base font-bold tracking-wide">
            {formatLargeNumber(value2020)}
          </span>
          <span className="text-sm font-light tracking-tight ml-1">
            in 2020
          </span>
        </div>
      )}
    </div>
  );
};

export default MetricDisplay;
