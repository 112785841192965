import React from "react";
import { createUseBackendData } from "../../hooks/data";
import { Card, BarChartCard } from "../../components/layouts/Cards";
import { useSelector } from "react-redux";

const VoteHistorySection = ({ queryString, comparisonQueryString }) => {
  const activeFilters = useSelector(
    (state) => state.districtPage.demographicFilters
  );
  const filterQueryString = Object.keys(activeFilters)
    .map((category) => {
      return activeFilters[category].map((value) => {
        return `&${category}=${value.replace(" to ", "-").toLowerCase()}`;
      });
    })
    .flat()
    .join("");

  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">Vote History</p>}
        id="vote_history"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />
          </>
        }
        className="pt-4"
      />
      <BarChartCard
        key="general"
        id="general"
        title="General Election Voting"
        subtitle="Among currently registered voters"
        endpoint={`voterfile/turnout?${queryString}${filterQueryString}&elections=g2016&elections=g2017&elections=g2018&elections=g2019&elections=g2020&elections=g2021&elections=g2022&elections=g2023`}
        comparisonEndpoint={
          comparisonQueryString !== undefined
            ? `voterfile/turnout?${comparisonQueryString}${filterQueryString}&elections=g2016&elections=g2017&elections=g2018&elections=g2019&elections=g2020&elections=g2021&elections=g2022&elections=g2023`
            : undefined
        }
        defaultToPercentage={true}
        chartProps={{
          valueFontSize: comparisonQueryString !== undefined ? 11 : 14,
        }}
        colspan={12}
      />
      <BarChartCard
        key="primary"
        id="primary"
        title="Primary Election Voting"
        subtitle="Among currently registered voters"
        endpoint={`voterfile/turnout?${queryString}${filterQueryString}&elections=p2016&elections=p2017&elections=p2018&elections=p2019&elections=p2020&elections=p2021&elections=p2022&elections=p2023`}
        comparisonEndpoint={
          comparisonQueryString !== undefined
            ? `voterfile/turnout?${comparisonQueryString}${filterQueryString}&elections=p2016&elections=p2017&elections=p2018&elections=p2019&elections=p2020&elections=p2021&elections=p2022&elections=p2023`
            : undefined
        }
        defaultToPercentage={true}
        chartProps={{
          valueFontSize: comparisonQueryString !== undefined ? 11 : 14,
        }}
        colspan={12}
      />
      <BarChartCard
        key="minor"
        id="minor"
        title="Minor Election Voting"
        subtitle="Among currently registered voters"
        footnote="Note: Many minor election vote history results are not available in the voter file unless specifically requested. These numbers may be incomplete."
        endpoint={`voterfile/turnout?${queryString}${filterQueryString}&elections=m2016&elections=m2017&elections=m2018&elections=m2019&elections=m2020&elections=m2021&elections=m2022&elections=m2023`}
        comparisonEndpoint={
          comparisonQueryString !== undefined
            ? `voterfile/turnout?${comparisonQueryString}${filterQueryString}&elections=m2016&elections=m2017&elections=m2018&elections=m2019&elections=m2020&elections=m2021&elections=m2022&elections=m2023`
            : undefined
        }
        defaultToPercentage={true}
        chartProps={{
          valueFontSize: comparisonQueryString !== undefined ? 11 : 14,
        }}
        colspan={12}
      />
    </>
  );
};

export { VoteHistorySection };
