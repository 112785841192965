import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SearchBar from "../../components/search/SearchBar";
import { clearError } from "../../store/searchPage";

const LandingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("bg-style-bg");
    dispatch(clearError()); // Clear any existing error when landing page mounts
    return () => {
      document.body.classList.remove("bg-style-bg");
    };
  }, [dispatch]);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-style-bg">
      <div className="mb-12 flex flex-col items-center justify-center">
        <div className="mb-6">
          <img
            src="Explorer_2024_Logo_WithLargeTag_Amber.png"
            alt="Explorer 2024 Logo"
            style={{ height: "160px" }}
          />
        </div>
        {/* <div className="text-center font-inter font-bold text-7xl text-slate-900 mb-6">
          <span>explorer</span>
          <span className="text-morange-700">.</span>
        </div> */}
      </div>
      <div className="w-[512px] h-[64px] max-w-xl">
        <SearchBar></SearchBar>
      </div>
    </div>
  );
};

export { LandingPage };
