import axios from 'axios';
import oktaAuth from '../auth/oktaAuth';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

apiClient.interceptors.request.use(async (config) => {
  const accessToken = await oktaAuth.getAccessToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;
