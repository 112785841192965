import React, { useEffect, useRef } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import oktaConfig from '../../auth/oktaConfig';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';


const Login = ({ oktaAuth }) => {
  // const widgetRef = useRef();

  // useEffect(() => {
  //   console.log('Initializing Okta Sign-In Widget');
  //   const widget = new OktaSignIn({
  //     baseUrl: oktaConfig.issuer.split('/oauth2')[0],
  //     clientId: oktaConfig.clientId,
  //     clientSecret: oktaConfig.clientSecret,
  //     redirectUri: oktaConfig.redirectUri,
  //     useInteractionCodeFlow: false,
  //     useClassicEngine: true,
  //     authParams: {
  //       pkce: false,
  //       issuer: oktaConfig.issuer,
  //       display: 'page',
  //       scopes: oktaConfig.scopes,
  //       responseType: oktaConfig.responseType,
  //     },
  //     i18n: {
  //       en: {
  //         'primaryauth.title': 'Sign in to Explorer', // Change 
  //       },
  //     },
  //     logo: '../../../Explorer_2024_Logo_WithLargeTag_Amber.png', // Add a custom logo
  //   });

  //   widget.renderEl(
  //     { el: widgetRef.current },
  //     (res) => {
  //       if (res.status === 'SUCCESS') {
  //         console.log('success');
  //         oktaAuth.token.getWithRedirect({
  //           sessionToken: res.sessionToken,
  //           originalUri: '/',
  //           responseType: oktaConfig.responseType,
  //           scopes: oktaConfig.scopes,
  //         }).catch((err) => {
  //           console.error('Error during getWithRedirect', err);
  //         });
  //       } else {
  //         console.log('Unhandled result status', res.status);
  //       }
  //     },
  //     (err) => {
  //       console.error('Login error', err);
  //       throw err;
  //     }
  //   );

  //   return () => {
  //     widget.remove();
  //   };
  // }, [oktaAuth]);

  // return <div ref={widgetRef} />;
  oktaAuth.signInWithRedirect();
};

export default withOktaAuth(Login);
